import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {
    Box,
    Checkbox,
    Chip,
    FormControl,
    IconButton,
    InputAdornment,
    ListItem,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import "./index.css";

const MultiSelectDropdownWithSearch = ({
                                           options = [],
                                           value = [],
                                           onChange,
                                           placeholder = "Search...",
                                           allowSelectAll = false,
                                           width = "100%",
                                       }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredOptions, setFilteredOptions] = useState(options);
    const open = Boolean(anchorEl);

    useEffect(() => {
        setFilteredOptions(
            options.filter((option) =>
                option.label.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );
    }, [searchQuery, options]);

    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleItemClick = (itemValue) => {
        onChange?.(itemValue); // Inform parent about the clicked item
    };

    const handleDeleteChip = (chipToDelete) => {
        onChange?.(chipToDelete); // Inform parent to handle chip removal
    };

    const handleSelectAll = () => {
        if (value.length === options.length) {
            onChange?.("clearAll");
        }
        onChange?.("selectAll");
    };

    return (
        <div style={{width: width || "100%"}}>
            <FormControl
                fullWidth
                className={`custom-select-container ${open ? "focused" : ""}`}
                style={{width}}
            >
                <ListItem
                    disablePadding
                    onClick={handleOpen}
                    style={{
                        borderBottom: "1px solid rgba(255, 255, 255, 0.20)",
                        borderRadius: 4,
                        cursor: "pointer",
                        position: "relative", // Ensure the container is relatively positioned
                    }}
                >
                    <ListItemText>
                        {value.length > 0 ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    gap: 0.5,
                                    maxHeight: "95px",
                                    overflowX: "auto",
                                }}
                            >
                                {value.map((val) => (
                                    <Chip
                                        key={val}
                                        label={options.find((option) => option.value === val)?.label}
                                        onDelete={() => handleDeleteChip(val)}
                                        deleteIcon={<CloseIcon className="chip-delete-icon" />}
                                        className="custom-chip"
                                    />
                                ))}
                            </Box>
                        ) : (
                            <span className="list-placeholder">{placeholder}</span>
                        )}
                    </ListItemText>
                    <IconButton
                        edge="end"
                        style={{
                            position: "absolute",
                            bottom: 4,
                            right: 4,
                            color: "#E7E7E7",
                        }}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </ListItem>

                <Popover
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                    }}
                    PaperProps={{
                        sx: {
                            width: "19%",
                            maxHeight: "340px",
                            borderRadius: "4px",
                            backgroundColor: "#272727",
                            boxShadow:
                                "0px 0px 1px 0px rgba(0, 0, 0, 0.31), 0px 8px 12px 0px rgba(0, 0, 0, 0.15)",
                        },
                    }}
                >
                    <MenuItem disableRipple disableGutters className="custom-search" >
                        <TextField
                            variant="outlined"
                            size="small"
                            placeholder="Search"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon sx={{ color: "#E7E7E7" }} />
                                    </InputAdornment>
                                ),
                            }}
                            className="custom-search-item"
                        />
                    </MenuItem>
                    {allowSelectAll && (
                        <MenuItem onClick={handleSelectAll} className="custom-menu-item">
                            <ListItemIcon>
                                <Checkbox
                                    checked={value.length === options.length}
                                    indeterminate={value.length > 0 && value.length < options.length}
                                    className="custom-checkbox"
                                />
                            </ListItemIcon>
                            <ListItemText className="list-item-text"> Select all </ListItemText>
                        </MenuItem>
                    )}
                    {filteredOptions.map((option) => (
                        <MenuItem
                            key={option.value}
                            onClick={() => handleItemClick(option.value)}
                            className="custom-menu-item"
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={value.includes(option.value)}
                                    className="custom-checkbox"
                                />
                            </ListItemIcon>
                            <ListItemText className="list-item-text">{option.label}</ListItemText>
                        </MenuItem>
                    ))}
                    {filteredOptions.length === 0 && (
                        <MenuItem disabled>
                            <em>No results found</em>
                        </MenuItem>
                    )}
                </Popover>
            </FormControl>
        </div>
    );
};

MultiSelectDropdownWithSearch.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ),
    value: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    allowSelectAll: PropTypes.bool,
    width: PropTypes.string,
};

export default MultiSelectDropdownWithSearch;
