import React, {useState} from "react";
import PropTypes from "prop-types";
import {FormControl, Autocomplete, TextField} from "@mui/material";
import "./index.css";

const SingleSelectAutocomplete = (props) => {
    const [error, setError] = useState(false);

    // Validate input
    const validate = (value) => {
        if (props?.required && !value) {
            setError(true);
        } else {
            setError(false);
        }
    };

    // Handle selection change
    const handleChange = (event, newValue) => {
        const selectedValue = newValue ? newValue.value : "";
        validate(selectedValue);
        props?.onChange?.(selectedValue);
    };

    // Handle blur validation
    const handleBlur = () => {
        validate(props.value);
    };

    return (
        <div style={{ width: props.width || "100%" }}>
            <FormControl
                error={error}
                className="custom-select-container"
                variant="standard"
                fullWidth
            >
                <Autocomplete
                    disabled={props?.disabled}
                    options={props.options}
                    getOptionLabel={(option) => option.label || ""}
                    value={props.options.find((option) => option.value === props.value) || null}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="custom-single-select-autocomplete"
                    noOptionsText={"No email found"}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder={props.placeholder || "Select"}
                        />
                    )}
                    isOptionEqualToValue={(option, value) => option.value === value.value}
                />
            </FormControl>
            {error && (
                <div className="custom-helper-text" >
                    {props?.errorMessage || "This field is required."}
                </div>
            )}
        </div>
    );
};

SingleSelectAutocomplete.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
            customContent: PropTypes.node,
        })
    ).isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    width: PropTypes.string,
};

export default SingleSelectAutocomplete;
