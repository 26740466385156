import axios from "axios";
import {ALERTS_URI} from "../Constant";
import {adsHeader} from "../utils/helper";

export const createAlertRule = async (requestData) => {
    const url = `${ALERTS_URI}/alert-rules`;
    const headers = {
        ...adsHeader(),
        'x-account-id': '34124',
        'x-amg-id': 'AMG-11243'
    };

    try {
        const response = await axios.post(url,requestData, { headers: headers });
        return response.data;
    } catch (error) {
        throw error.response ? error.response.data : error.message;
    }
};