import axios from "axios";
import {adsHeader} from "../utils/helper";
import {PROXY_LAYER_USER_MANAGEMENT_URI} from "../Constant";

export const inviteAmagiFridayUsers = (body={}, resendInvite=false) => {
    let data = JSON.stringify({
        query: `mutation CreateAmagiFridayUser($input: CreateAmagiFridayUserInput!) {
                    createAmagiFridayUser(createAmagiFridayUser: $input) {
                        user_name
                        message
                    }
        }`,
        variables: { input: {...body} }
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: resendInvite ? `${PROXY_LAYER_USER_MANAGEMENT_URI}resend-invite` : `${PROXY_LAYER_USER_MANAGEMENT_URI}invite`,
        headers: headers,
        data: data
    };

    // return data;

    return axios.request(config)
        .then((response) => {
            if (response?.data?.data && response.status === 201) {
                return response.data.data;
            }
            else if (response?.data?.data && response.status === 200){
                return response.data.data;
            }
            else if (response?.data?.data && response.status === 409){
                return response.data.data;
            }
            else {
                return [];
            }
        })
        .catch((err) => {
            return { createAmagiFridayUser: err.response.data};
        });
};


