import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./index.css";
import {Button, Grid, Skeleton, Typography} from "@mui/material";
import Notification from "../../../Components/Notification";
import NoAlertRulesIcon from "../../../assests/icons/NoAlertRulesIcon";
import AlertRulesBreadCrumbs from "../../../Components/BreadCrumbs/AlertsBreadCrumbs/AlertRulesBreadCrumbs";
import AddIcon from "../../../assests/icons/addIcon";
import DynamicTable from "../../../Components/Tables/DynamicTable";
import {alertRulesTableConfig} from "../../../Configs/AlertsConfig/RulesTable";
import DynamicTableTopFilter from "../../../Components/TableTopFilters/DynamicTableTopFilter";
import CustomPagination from "../../../Common/CustomPagination";
import Drawer from "@mui/material/Drawer";
import { DynamicSidebarForm} from "../../../Components/DynamicSidebarForm";
import {CreateRulesFormConfig} from "../../../Configs/AlertsConfig/CreateRulesFormConfig";
import {getAllAlertRulesPaginated} from "../../../api/getAllAlertRulesPaginated";
import {getAllAdsPlusInventoryList} from "../../../api/getAllAdsPlusInventoryList";
import {createAlertRule} from "../../../api/createAlertRule";
import useDebounce from "../../../utils/useDebounce";
import {
    ALERT_RULE_CREATED,
    ALERT_RULE_METRIC_MAP,
    ALL_DATA_FETCHED, ERROR_ALERT_RULE_CREATED,
    ERROR_NOTIFICATION_TYPE,
    SUCCESS_NOTIFICATION_TYPE
} from "../../../Constant";

const RulesDashboard = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [loading, setLoading] = useState(true);
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const navigation = useNavigate();
    const [searchedData, setSearchedData] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [pageSize, setPageSize] = useState(20);
    const [isRulesSidebarOpen, setIsRulesSidebarOpen] = useState(false);
    const [totalAlertRuleCount, setTotalAlertRuleCount] = useState(0);
    const [page, setPage] = useState(1);
    const [allSupplyPartnerList, setAllSupplyPartnerList] = useState([]);
    const [allSupplyTagList, setAllSupplyTagList] = useState([]);
    const [allDemandPartnerList, setAllDemandPartnersList] = useState([]);
    const [allDemandTagList, setAllDemandTagList] = useState([]);
    const [allChannelList, setAllChannelList] = useState([]);
    const [filtersBody, setFiltersBody] = useState({});

    const [formValues, setFormValues] = useState(() => {
        const initialState = {};
        CreateRulesFormConfig().forEach((field) => {
            if (field.key) {
                if (field.defaultValue !== undefined) {
                    initialState[field.key] = field.defaultValue;
                } else {
                    initialState[field.key] = field.type === "multi_select_with_search" ? [] : "";
                }
            }
        });
        return initialState;
    });

    const handlePageChange = (newPage, newSize) => {
        setPage(newPage);
        setPageSize(newSize);
    };
    const handlePageSizeChange = (newSize) => {
        setPageSize(newSize);
    };

    useEffect(() => {
        // {props?.formValues?.metric} for last {props.formValues.duration ? props.formValues.duration : "__"} hrs
        let name = `${formValues?.metric ? ALERT_RULE_METRIC_MAP[formValues?.metric] : "__"} for last ${formValues?.duration ? formValues?.duration : "__"} hrs`;
        setFormValues((prev) => {
            return {
                ...prev,
                alert_rule_name: name,
            };
        });
    }, [formValues]);

    useEffect(()=> {
        const getData = async () => {
            try {
                const allEntity = await getAllAdsPlusInventoryList();
                const supplyPartners = allEntity?.AllSupplyPartners.map((item) => {
                    return {
                        value: item?.SupplyPartnerId,
                        label: item?.SupplyPartnerName,
                    }
                })

                const supplyTags = allEntity?.AllSupplyTags.map((item) => {
                    return {
                        value: item?.SupplyTagId,
                        label: item?.SupplyTagName,
                    }
                })

                const demandPartner = allEntity?.AllDemandPartners.map((item) => {
                    return {
                        value: item?.DemandPartnerId,
                        label: item?.DemandPartnerName,
                    }
                })

                const demandTag = allEntity?.AllDemandTags.map((item) => {
                    return {
                        value: item?.DemandTagId,
                        label: item?.DemandTagName,
                    }
                })

                const channel = allEntity?.AllChannels.map((item) => {
                    return {
                        value: item?.ChannelId,
                        label: item?.ChannelName,
                    }
                })

                if (allEntity) {
                    setAllSupplyPartnerList(supplyPartners);
                    setAllSupplyTagList(supplyTags);
                    setAllDemandPartnersList(demandPartner);
                    setAllDemandTagList(demandTag);
                    setAllChannelList(channel);
                }
            }catch(error) {
                console.log(error);
            }

        }
        getData();
    }, [])


    const loadData = async () => {
        setTableDataLoading(true);
        try {
            const allRules = await getAllAlertRulesPaginated(pageSize, page, filtersBody);
            !totalAlertRuleCount && setTotalAlertRuleCount(allRules?.total);
            setSearchedData(allRules?.data);
            setIsOpen(true);
            setNotificationMessage(ALL_DATA_FETCHED);
            setAlertType(SUCCESS_NOTIFICATION_TYPE);
            setLoading(false);
        }
        catch (error) {
            setIsOpen(true);
            setNotificationMessage(error);
            setAlertType(ERROR_NOTIFICATION_TYPE);
        }
        finally {
            setTableDataLoading(false);
        }
    }

    useEffect( () => {
        loadData();
    }, [page, pageSize, filtersBody]);

    const handleNotificationClose = () => {
        setIsOpen(false);
    };

    const onClickBreadCrumbsLink = (link) => {
        navigation(link);
    };

    const handleDebouncedSearch = useDebounce((searchText) => {
        let text = searchText.trim();
        setFiltersBody({...filtersBody, "alert_rule_name": text});
    }, 400);

    const toggleDrawer = (state) => () => {
        setIsRulesSidebarOpen(state);
    }

    const handleInputChange = (key, value, isMultiSelect = false) => {
        if (key === "alert_type") {
            setFormValues((prev) => {
                const updates = { threshold: "", duration: "", deviation_type: "", frequency: "" }; // Common update for both "Spot" and "Trend"
                if (value === "Spot") {
                    updates["frequency"] = "Hourly";
                }
                return { ...prev, ...updates };
            });
        }
        if (key === "deviation_type") {
            setFormValues((prev) => {
                const updates = { threshold: "" };
                return { ...prev, ...updates };
            });
        }
        if (key === "alert_level") {
            setFormValues((prev) => {
                const updates = { supply_partners: [], channels: [], supply_tags: [], demand_tags: [] };
                return { ...prev, ...updates };
            });
        }

        setFormValues((prev) => {
            if (isMultiSelect) {
                const currentValues = prev[key] || [];

                if (value === "selectAll") {
                    return {
                        ...prev,
                        [key]: key === "demand_partners" ? allDemandPartnerList.map((item) => item.value) : key === "demand_tags" ? allDemandTagList.map((item) => item.value) : key === "supply_partners" ? allSupplyPartnerList.map((item) => item.value) : key === "supply_tags" ? allSupplyTagList.map((item) => item.value) : key === "channels" ? allChannelList.map((item) => item.value) : [],
                    };
                }

                if (value === "clearAll") {
                    return {
                        ...prev,
                        [key]: [],
                    };
                }

                if (currentValues.includes(value)) {
                    // Remove the value if it exists
                    return {
                        ...prev,
                        [key]: currentValues.filter((item) => item !== value),
                    };
                } else {
                    // Add the value if it doesn't exist
                    return {
                        ...prev,
                        [key]: [...currentValues, value],
                    };
                }
            } else {
                return {
                    ...prev,
                    [key]: value,
                };
            }
        });
    };

    const handleCreateAlertRule = async () => {
        try {
            const entity_ids = formValues?.alert_level === "DemandPartner" ? formValues?.demand_partners :
                formValues?.alert_level === "DemandTag" ? formValues?.demand_tags :
                    formValues?.alert_level === "SupplyPartner" ? formValues?.supply_partners :
                        formValues?.alert_level === "SupplyTag" ? formValues?.supply_tags :
                            formValues?.alert_level === "Channel" ? formValues?.channels : [];
            const requestData = {
                alert_rule_name: formValues?.alert_rule_name,
                alert_type: formValues?.alert_type,
                alert_level: formValues?.alert_level,
                metric_name: formValues?.metric,
                frequency: formValues?.frequency,
                severity: formValues?.severity,
                enabled: formValues?.status, //need to change
                duration: Number(formValues?.duration),
                alert_level_entity_ids: entity_ids,
                alerting_mode: {
                    jira: {
                        enabled: false
                    }
                }
            }

            const response = await createAlertRule(requestData).then((data) => {return data});

            if(response && response.data && response.data.alert_rule_id) {
                toggleDrawer(false)() ;
                setIsOpen(true);
                setNotificationMessage(ALERT_RULE_CREATED);
                setAlertType(SUCCESS_NOTIFICATION_TYPE);
                loadData();
            }else{
                toggleDrawer(false)();
                setIsOpen(true);
                if (response.error_code || response.error_codes) {
                    setNotificationMessage(response.message || ERROR_ALERT_RULE_CREATED);
                } else {
                    setNotificationMessage(ERROR_ALERT_RULE_CREATED);
                }
                setAlertType(ERROR_NOTIFICATION_TYPE);
            }
        }catch(error) {
            console.log("Error while creating Alert Rule", error);
        }
    }

    return (
        <React.Fragment>
            <Drawer
                anchor="right"
                open={isRulesSidebarOpen}
                onClose={toggleDrawer(false)}
                className="create-rules-sidebar"
            >
                <DynamicSidebarForm
                    handleCloseForm={toggleDrawer(false)}
                    formConfig={CreateRulesFormConfig(allSupplyPartnerList, allChannelList, allSupplyTagList, allDemandTagList, allDemandPartnerList)}
                    handleInputChange={handleInputChange}
                    formValues={formValues}
                    allowSubmit={true}
                    handleSubmit={handleCreateAlertRule}
                />
            </Drawer>
            <Grid container rowSpacing={2}>
                <Grid item xs={12} className="amg-bread-crumbs">
                    <AlertRulesBreadCrumbs onClickBreadCrumbsLink={onClickBreadCrumbsLink} />
                </Grid>
                <Grid item xs={12} className="rules-header">
                    <Typography>Alert rules</Typography>
                    { totalAlertRuleCount !== 0 && !loading && (
                        <Button className="orange-button-with-icon orange-button" onClick={toggleDrawer(true)}>
                            <AddIcon color={"black"}/> Create alert rule
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    {loading ? (
                        <Skeleton variant="rectangular" width="100%" height={740} className="alerts-skeleton-loading" />
                    ) : totalAlertRuleCount === 0 ? (
                        <div className="empty-alert-rules-content">
                            <NoAlertRulesIcon />
                            <div className="es-heading">No alert rule setup yet</div>
                            <div className="es-sub-heading">Create your first alert rule</div>
                            <Button className="create-alert">
                                <AddIcon color="black" />
                                Create alert rule
                            </Button>
                        </div>
                    ) : (
                        <React.Fragment>
                            <Grid item xs={12} className="amg-filter-content">
                                <DynamicTableTopFilter
                                    count={totalAlertRuleCount}
                                    titleText={"alerts"}
                                    onSearch={handleDebouncedSearch}
                                    showFilter={false}
                                    filterOpen={() => setIsFilterOpen(true)}
                                />
                            </Grid>
                            <Grid container className="dynamic-table-content">
                                <DynamicTable
                                    tableContent={searchedData}
                                    onClick={() => setIsOpen(true)}
                                    isLoading={tableDataLoading}
                                    searchedText={searchText}
                                    tableConfig={alertRulesTableConfig}
                                    highlightUnseen={false}
                                    emptyStateMessage={"No alert rules found!"}
                                    // onSort={handleSort}
                                    onClickAction={(action, data) => {
                                        console.log({ action, data });
                                    }}
                                />
                            </Grid>
                            {searchedData.length > 0 && (
                                <Grid item xs={12} >
                                    <div className="amg-pagination-section" >
                                        <CustomPagination
                                            totalItems={totalAlertRuleCount}
                                            itemsPerPage={pageSize}
                                            onPageChange={handlePageChange}
                                            onPageSizeChange={handlePageSizeChange}
                                            apiDriven={false}
                                        />
                                    </div>
                                </Grid>
                            )}
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
            <Notification
                open={isOpen}
                message={notificationMessage}
                handleClose={handleNotificationClose}
                type={alertType}
            />
        </React.Fragment>
    );
};

export default RulesDashboard;
