import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getAllAdsPlusInventoryList = async () => {
    let data = JSON.stringify({
        query: `query MyQuery {
                      getAllAdsPlusInventoryList {
                        AllChannels {
                          ChannelId
                          ChannelName
                        }
                        AllDemandPartners {
                          DemandPartnerId
                          DemandPartnerName
                        }
                        AllDemandTags {
                          DemandTagId
                          DemandTagName
                        }
                        AllSupplyPartners {
                          SupplyPartnerName
                          SupplyPartnerId
                        }
                        AllSupplyTags {
                          SupplyTagId
                          SupplyTagName
                        }
                      }
                    }`
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    return axios.request(config)
        .then((response) => {
            if (response?.data?.data?.getAllAdsPlusInventoryList && response.status === 200) {
                return response.data.data.getAllAdsPlusInventoryList;
            } else {
                return [];
            }
        })
        .catch((error) => {
            console.error('Request failed:', error);
            return null;
        });
};
