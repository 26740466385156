const EmailIcon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon-Left">
                <path id="Vector"
                      d="M2.125 2.5H17.875C18.1071 2.5 18.3296 2.5878 18.4937 2.74408C18.6578 2.90036 18.75 3.11232 18.75 3.33333V16.6667C18.75 16.8877 18.6578 17.0996 18.4937 17.2559C18.3296 17.4122 18.1071 17.5 17.875 17.5H2.125C1.89294 17.5 1.67038 17.4122 1.50628 17.2559C1.34219 17.0996 1.25 16.8877 1.25 16.6667V3.33333C1.25 3.11232 1.34219 2.90036 1.50628 2.74408C1.67038 2.5878 1.89294 2.5 2.125 2.5V2.5ZM17 6.03167L10.063 11.9483L3 6.01333V15.8333H17V6.03167ZM3.44713 4.16667L10.0534 9.71833L16.5643 4.16667H3.44713Z"
                      fill="#E7E7E7"/>
            </g>
        </svg>
    )
}

export default EmailIcon;