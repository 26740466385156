export const getRequestsListQuery = `query MyQuery($where: AdsPlusRequestConfigWhereQuery = {}) {
  getAdsPlusRequestConfig(limit: 10, offset: 0, where: $where) {
    total_count
    data {
      comments
      created_at
      request_job_id
      requested_user
      updated_at
      type
      status
      meta 
      config
    }
  }
}`;
