export const issueDetailsTableConfig = [
    {
        key: "metric_name",
        label: "Metrics",
    },
    {
        key: "triggered_value",
        label: "Triggered Value",
    },
    {
        key: "previous_value",
        label: "Previous Value",
    },
    {
        key: "deviation",
        label: "Deviation",
    },
];
