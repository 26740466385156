export const DialogDataConfig = (type) => {
    if(type.toLowerCase() === 'delete user') return {
        actionType: 'delete',
        dialogTitle: 'Delete user',
        dialogDescription1: 'Are you sure you want to delete this user?',
        dialogDescription2: 'They will be permanently removed from the system and has to be added as a new user if needed.',
        cancelButtonText: 'Cancel',
        actionButtonText: 'Delete',
        actionButtonColor: 'red'
    };
};