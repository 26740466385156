import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import "./index.css";

const RadioButtonInput = (props) => {
    const [error, setError] = useState(false);
    const [isFocused, setIsFocused] = useState(false); // Track focus state

    const validate = (value) => {
        if (props?.required && (value === "" || value === undefined)) {
            setError(true);
        } else {
            setError(false);
        }
    };

    const handleChange = (event) => {
        const value = event.target.value;
        validate(value);
        props?.onChange(value);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
        validate(props?.value);
    };

    return (
        <div
            className={`custom-radio-container ${isFocused ? "focused" : ""}`}
            style={{width: props?.width || "100%"}}
        >
            <FormControl
                component="fieldset"
                fullWidth
                className={`radio-group-container ${isFocused ? "focused" : ""}`}
                error={error}
            >
                <RadioGroup
                    value={String(props?.value) || ""}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    row={props?.isRow || false}
                >
                    {props?.options.map((option) => (
                        <FormControlLabel
                            key={String(option.value)}
                            value={String(option.value)}
                            control={<Radio/>}
                            label={option.label}
                            className="radio-option"
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            {error && (
                <div className="custom-helper-text">
                    {props?.errorMessage || "This field is required."}
                </div>
            )}
        </div>
    );
};

RadioButtonInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
        })
    ).isRequired,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    specialIcon: PropTypes.node,
    popoverText: PropTypes.string,
    boardLink: PropTypes.string,
    isRow: PropTypes.bool,
    width: PropTypes.string,
};

export default RadioButtonInput;
