export const demandGoalTrackingData = (month = 1) => {
    return({
            "data": {
                "demandGoalTracking": {
                    "month": month,
                    "target": 10000000,
                    "projected": 8500000,
                    "achieved": 7000000,
                    "track": "on-track"
                }
            }
    })
}