import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormControl, Input } from "@mui/material";
import "./index.css";

const SimpleTextInput = (props) => {
    const [error, setError] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [errorMessage, setErrorMessage] = useState(props?.errorMessage);

    const validate = (value) => {
        if (props?.required && (!value || value.trim() === "")) {
            setError(true);
            setErrorMessage(props?.errorMessage);
            return false;
        }
        if (props?.validationPattern && !new RegExp(props.validationPattern).test(value)) {
            setError(true);
            setErrorMessage(props?.validationErrorMessage || "Invalid input");
            return false;
        }
        setError(false);
        setErrorMessage(props?.errorMessage);
        return true;
    };

    const handleChange = (event) => {
        const value = event.target.value;
        if(value.length <= props?.maxLength) {
            props?.onChange(value);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
        validate(props?.value);
    };

    return (
        <div style={{ width: props.width || "100%" }}>
            <FormControl
                className={`custom-select-container ${isFocused ? "focused" : ""}`}
                fullWidth
                style={{ width: props?.width || "100%" }}
            >
                <Input
                    disabled={props?.disabled}
                    type="text"
                    value={props?.value || ""}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder={props?.placeholder || "Enter text"}
                    disableUnderline
                    className="number-input-field"
                />
            </FormControl>
            {error && (
                <div className="custom-helper-text">
                    {errorMessage || "Invalid input."}
                </div>
            )}
        </div>
    );
};

SimpleTextInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    validationPattern: PropTypes.string, // Regex pattern for validation
    disabled: PropTypes.bool,
    width: PropTypes.string,
};

export default SimpleTextInput;
