export const usersTableConfig = [
    {
        key: "full_name",
        label: "NAME",
        width: "100px",
        type: "name_with_avatar",
    },
    {
        key: "user_name",
        label: "EMAIL ID",
        width: "100px",
    },
    {
        key: "user_roles",
        label: "ROLE",
        width: "100px",
    },
    {
        key: "created_on",
        label: "ADDED ON",
        width: "100px",
        type: "date",
        sortIcon: true,
    },
    {
        key: "last_login_at",
        label: "LAST LOGGED IN",
        width: "100px",
        type: "date",
    },
    {
        key: "user_status",
        label: "STATUS",
        width: "150px",
        type: "status_boolean",
    },
    {
        key: "user_management_actions",
        label: " ",
        allowActions: true,
        width: "0px",
        disableSelfEdit: true,
        options: [
            "Resend invite",
            "Change role",
            "Delete user",
        ],
    },
];
