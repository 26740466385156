import axios from "axios";
import {adsHeader} from "../utils/helper";
import {PROXY_LAYER_USER_MANAGEMENT_URI} from "../Constant";

export const updateAmagiFridayUser = (body={}) => {
    let data = JSON.stringify({
        query: `mutation UpdateAmagiFridayUser($input: CreateAmagiFridayUserInput!) {
                    updateAmagiFridayUser(updateAmagiFridayUser: $input) {
                        user_name
                        message
                    }
        }`,
        variables: { input: {...body} }
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `${PROXY_LAYER_USER_MANAGEMENT_URI}update`,
        headers: headers,
        data: data
    };

    // return data;

    return axios.request(config)
        .then((response) => {
            if (response?.data?.data && response.status === 200) {
                return response.data.data;
            } else {
                return [];
            }
        })
        .catch((_) => {
            return null;
        });
};


