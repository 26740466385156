import React, {useEffect, useState} from "react"
import {Grid, Typography} from "@mui/material";
import TableContentPlatform from "../../../Components/Tables/TableContentPlatfrom";
import {
    PLATFORM_DETAILS_PAGE_LINK,
    TABLE_HEADER_PLATFORMS,
    ONGOING_API_CALL_KEY,
    NO_DATA_AVAILABLE,
    ALL_DATA_FETCHED,
    SUCCESS_TYPE,
    SEVERITY_TYPE,
    DATA_IN_LOADING_STATE,
    WARNING_NOTIFICATION_TYPE,
    SEARCHED_TEXT_NOT_FOUND,
    USER_DATA_KEY,
    MAX_LIMIT_SHOW_TABLE_DATA,
    VIEW_CHANNEL_TYPE,
    TIMEOUT_NAVIGATION_CALL,
    INITIAL_PAGE,
    SELECTED_CHANNEL_KEY,
    SELECTED_PLATFORM_KEY,
    ADS_PLUS_PLATFORM_ID
} from "../../../Constant";
import {useNavigate} from "react-router-dom"
import FilterContent from "../../../Components/TableTopFilters/FilterContent";
import {getPlatformListing} from "../../../api/getPlatformListing";
import {setLocalStorageData, getLocalStorageData} from "../../../utils/localStorageHelper";
import Notification from "../../../Components/Notification";
import {isEmpty} from "../../../utils/helper";
import useWebStorageHelper from "../../../utils/sessionStorageHelper";

function PlatformListing(props) {
    const [platformsData, setPlatformsData] = useState([])
    const [offset, setOffset] = useState(0);
    const [loading, setLoading] = useState(true)
    const [hasMoreData, setHasMoreData] = useState(true);
    const [isOpen, setIsOpen] = useState(false)
    const [notificationMessage, setNotificationMessage] = useState("")
    const [searchedData, setSearchedData] = useState([])
    const [searchText, setSearchText] = useState("")
    const [alertType, setAlertType] = useState("")
    const [totalCount,setTotalCount] = useState(0)
    const navigate = useNavigate()


    useEffect(() => {
        loadData(0);
        useWebStorageHelper.removeOnbFile();
    }, []);


    const loadData = async (offset = 0) => {
        setLoading(true)
        setHasMoreData(false)
        const user_data = getLocalStorageData(USER_DATA_KEY, true);
        let userEmail = user_data?.email || ""

        const platformResponse= await getPlatformListing(offset, MAX_LIMIT_SHOW_TABLE_DATA, userEmail);
        const platformResponseData = platformResponse?.data || []


        if (platformResponseData && platformResponseData.length > 0) {
            setTotalCount(platformResponse?.total_count || 0)
            const getStateData = offset > 0 ? Object.assign([], platformsData) : []
            getStateData.push(...platformResponseData)
            setPlatformsData(getStateData);
            setSearchedData(getStateData)
            setLoading(false)
            setHasMoreData(true)
        } else if (platformResponseData && platformResponseData.length === 0) {
            setHasMoreData(false)
            setIsOpen(true)
            setNotificationMessage(ALL_DATA_FETCHED)
            setAlertType(SUCCESS_TYPE)
            setLoading(false)
        }else {
            setPlatformsData([])
            setSearchedData([])
            setNotificationMessage(DATA_IN_LOADING_STATE)
            setAlertType(WARNING_NOTIFICATION_TYPE)
            setIsOpen(true)
        }

    }

    const searchFunction = (text) => {
        let contentData = []
        setHasMoreData(false)

        contentData = platformsData.filter((data) =>
            data.name.toLowerCase().includes(text.toLowerCase())
        );

        if (contentData.length > 0 && !isEmpty(text)) {
            setSearchedData(contentData);
        } else if (contentData.length === 0 && !isEmpty(text)) {
            setSearchedData([])
            setIsOpen(true)
            setNotificationMessage(SEARCHED_TEXT_NOT_FOUND)
            setAlertType(WARNING_NOTIFICATION_TYPE)
        } else {
            setSearchedData(platformsData);
            setHasMoreData(true)
        }
    }


    const onClickPlatform = (amg_id = "") => {
        navigate(`${PLATFORM_DETAILS_PAGE_LINK}${amg_id}`)
    }

    const changeOffset = (count) => {
        const getOnGoingApiCall = getLocalStorageData(ONGOING_API_CALL_KEY, false)
        if (count > 0 && getOnGoingApiCall === "false") {
            setOffset(count)
            loadData(count)
        }

    }
    const handleClose = () => {
        setIsOpen(false);
    }

    const onSearchFilter = (text) => {
        setSearchText(text)
        searchFunction(text)
    }

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} className="amg-body-page-header ">
                    <div className='platform-header' >
                        <Typography> Platforms </Typography>
                        <div className='amg-body-ads-platform' onClick={()=> {
                            setLocalStorageData(SELECTED_PLATFORM_KEY, false, ADS_PLUS_PLATFORM_ID)
                            onClickPlatform(ADS_PLUS_PLATFORM_ID)
                        }} > Ads plus platform  </div>
                    </div>
                </Grid>
                <Grid item xs={12} className="amg-body-content">
                    <Grid item xs={12} className="amg-filter-content">
                        <FilterContent
                            showFilter={false}
                            titleText="Platform"
                            count={totalCount}
                            onSearch={onSearchFilter}
                        />
                    </Grid>
                    <Grid container className="amg-body-table-content" sx={{maxHeight: "620px"}}>
                        <TableContentPlatform
                            tableHeader={TABLE_HEADER_PLATFORMS}
                            tableContent={searchedData}
                            onClickPlatform={onClickPlatform}
                            currentOffset={offset}
                            newOffset={changeOffset}
                            isLoading={loading}
                            isHavingMoreData={hasMoreData}
                            searchText={searchText}
                        />
                    </Grid>
                    <Notification
                        open={isOpen}
                        message={notificationMessage}
                        handleClose={handleClose}
                        type={alertType}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default PlatformListing