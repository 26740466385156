export const SUPPLY_LIST_DATA = [
    {
        id: 1,
        amagi_monetization_rights: 'Full Rights',
        supply_partner: 'Partner X',
        channel: 'Channel 1',
        app_site: 'App 1',
        os: 'iOS',
        device_type: 'Mobile',
        bundle_id_domain: 'com.example.app1',
        app_store_url: 'https://apps.apple.com/app/app1'
    },
    {
        id: 2,
        amagi_monetization_rights: 'Partial Rights',
        supply_partner: 'Partner Y',
        channel: 'Channel 2',
        app_site: 'Site 1',
        os: 'Android',
        device_type: 'Desktop',
        bundle_id_domain: 'com.example.site1',
        app_store_url: 'https://play.google.com/store/apps/site1'
    },
    {
        id: 3,
        amagi_monetization_rights: 'Full Rights',
        supply_partner: 'Partner Z',
        channel: 'Channel 3',
        app_site: 'App 2',
        os: 'Android',
        device_type: 'Tablet',
        bundle_id_domain: 'com.example.app2',
        app_store_url: 'https://play.google.com/store/apps/app2'
    },
    {
        id: 4,
        amagi_monetization_rights: 'Partial Rights',
        supply_partner: 'Partner W',
        channel: 'Channel 4',
        app_site: 'App 3',
        os: 'iOS',
        device_type: 'Mobile',
        bundle_id_domain: 'com.example.app3',
        app_store_url: 'https://apps.apple.com/app/app3'
    },
    {
        id: 5,
        amagi_monetization_rights: 'Full Rights',
        supply_partner: 'Partner V',
        channel: 'Channel 5',
        app_site: 'App 4',
        os: 'iOS',
        device_type: 'Desktop',
        bundle_id_domain: 'com.example.app4',
        app_store_url: 'https://apps.apple.com/app/app4'
    },
    {
        id: 6,
        amagi_monetization_rights: 'Partial Rights',
        supply_partner: 'Partner U',
        channel: 'Channel 6',
        app_site: 'App 5',
        os: 'Android',
        device_type: 'Mobile',
        bundle_id_domain: 'com.example.app5',
        app_store_url: 'https://play.google.com/store/apps/app5'
    },
    {
        id: 7,
        amagi_monetization_rights: 'Full Rights',
        supply_partner: 'Partner T',
        channel: 'Channel 7',
        app_site: 'Site 2',
        os: 'iOS',
        device_type: 'Tablet',
        bundle_id_domain: 'com.example.site2',
        app_store_url: 'https://apps.apple.com/app/site2'
    },
    {
        id: 8,
        amagi_monetization_rights: 'Partial Rights',
        supply_partner: 'Partner S',
        channel: 'Channel 8',
        app_site: 'App 6',
        os: 'Android',
        device_type: 'Desktop',
        bundle_id_domain: 'com.example.app6',
        app_store_url: 'https://play.google.com/store/apps/app6'
    },
    {
        id: 9,
        amagi_monetization_rights: 'Full Rights',
        supply_partner: 'Partner R',
        channel: 'Channel 9',
        app_site: 'App 7',
        os: 'iOS',
        device_type: 'Mobile',
        bundle_id_domain: 'com.example.app7',
        app_store_url: 'https://apps.apple.com/app/app7'
    },
    {
        id: 10,
        amagi_monetization_rights: 'Partial Rights',
        supply_partner: 'Partner Q',
        channel: 'Channel 10',
        app_site: 'App 8',
        os: 'Android',
        device_type: 'Tablet',
        bundle_id_domain: 'com.example.app8',
        app_store_url: 'https://play.google.com/store/apps/app8'
    },
    {
        id: 11,
        amagi_monetization_rights: 'Full Rights',
        supply_partner: 'Partner P',
        channel: 'Channel 11',
        app_site: 'App 9',
        os: 'iOS',
        device_type: 'Desktop',
        bundle_id_domain: 'com.example.app9',
        app_store_url: 'https://apps.apple.com/app/app9'
    },
    {
        id: 12,
        amagi_monetization_rights: 'Partial Rights',
        supply_partner: 'Partner O',
        channel: 'Channel 12',
        app_site: 'Site 3',
        os: 'Android',
        device_type: 'Mobile',
        bundle_id_domain: 'com.example.site3',
        app_store_url: 'https://play.google.com/store/apps/site3'
    },
    {
        id: 13,
        amagi_monetization_rights: 'Full Rights',
        supply_partner: 'Partner N',
        channel: 'Channel 13',
        app_site: 'App 10',
        os: 'iOS',
        device_type: 'Tablet',
        bundle_id_domain: 'com.example.app10',
        app_store_url: 'https://apps.apple.com/app/app10'
    },
    {
        id: 14,
        amagi_monetization_rights: 'Partial Rights',
        supply_partner: 'Partner M',
        channel: 'Channel 14',
        app_site: 'App 11',
        os: 'Android',
        device_type: 'Desktop',
        bundle_id_domain: 'com.example.app11',
        app_store_url: 'https://play.google.com/store/apps/app11'
    },
    {
        id: 15,
        amagi_monetization_rights: 'Full Rights',
        supply_partner: 'Partner L',
        channel: 'Channel 15',
        app_site: 'App 12',
        os: 'iOS',
        device_type: 'Mobile',
        bundle_id_domain: 'com.example.app12',
        app_store_url: 'https://apps.apple.com/app/app12'
    },
    {
        id: 16,
        amagi_monetization_rights: 'Partial Rights',
        supply_partner: 'Partner K',
        channel: 'Channel 16',
        app_site: 'Site 4',
        os: 'Android',
        device_type: 'Desktop',
        bundle_id_domain: 'com.example.site4',
        app_store_url: 'https://play.google.com/store/apps/site4'
    },
    {
        id: 17,
        amagi_monetization_rights: 'Full Rights',
        supply_partner: 'Partner J',
        channel: 'Channel 17',
        app_site: 'App 13',
        os: 'iOS',
        device_type: 'Mobile',
        bundle_id_domain: 'com.example.app13',
        app_store_url: 'https://apps.apple.com/app/app13'
    },
    {
        id: 18,
        amagi_monetization_rights: 'Partial Rights',
        supply_partner: 'Partner I',
        channel: 'Channel 18',
        app_site: 'App 14',
        os: 'Android',
        device_type: 'Tablet',
        bundle_id_domain: 'com.example.app14',
        app_store_url: 'https://play.google.com/store/apps/app14'
    },
    {
        id: 19,
        amagi_monetization_rights: 'Full Rights',
        supply_partner: 'Partner H',
        channel: 'Channel 19',
        app_site: 'App 15',
        os: 'iOS',
        device_type: 'Desktop',
        bundle_id_domain: 'com.example.app15',
        app_store_url: 'https://apps.apple.com/app/app15'
    },
    {
        id: 20,
        amagi_monetization_rights: 'Partial Rights',
        supply_partner: 'Partner G',
        channel: 'Channel 20',
        app_site: 'Site 5',
        os: 'Android',
        device_type: 'Mobile',
        bundle_id_domain: 'com.example.site5',
        app_store_url: 'https://play.google.com/store/apps/site5'
    }
];
