export const AlertsSeverityIcons = (severity="", color=null) => {
    if (severity.toLowerCase() === "critical"){
        return (
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="arrow-up">
                    <path id="Vector"
                          d="M9.99902 5.79537L5.33215 10.2502L3.99902 8.97768L9.99902 3.25024L15.999 8.97768L14.6659 10.2502L9.99902 5.79537Z"
                          fill={ color || "#FF6961"}/>
                    <path id="Vector_2"
                          d="M9.99902 12.0454L5.33215 16.5002L3.99902 15.2277L9.99902 9.50024L15.999 15.2277L14.6659 16.5002L9.99902 12.0454Z"
                          fill={ color || "#FF6961"}/>
                </g>
            </svg>
        )
    }
    else if (severity.toLowerCase() === "high"){
        return (
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="arrow-up">
                    <path id="Vector" d="M10 9.54513L5.33312 14L4 12.7274L10 7L16 12.7274L14.6669 14L10 9.54513Z"
                          fill={ color ||"#FF6961"} />
                </g>
            </svg>
        )
    }
    else if (severity.toLowerCase() === "medium"){
        return (
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="arrow-up">
                    <g id="Union">
                        <path d="M3.75 8.9375H16.25V7.0625H3.75V8.9375Z" fill={ color ||"#F1B521"} />
                        <path d="M3.75 13.9375H16.25V12.0625H3.75V13.9375Z" fill={ color ||"#F1B521"} />
                    </g>
                </g>
            </svg>
        )
    }
    else if (severity.toLowerCase() === "low"){
        return (
            <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="arrow-down">
                    <path id="Vector" d="M10 11.4549L5.33312 7L4 8.27256L10 14L16 8.27256L14.6669 7L10 11.4549Z"
                          fill={ color ||"#82C77F"} />
                </g>
            </svg>
        )
    }
    else {
        return (
            <></>
        )
    }
}
