export const getAllFiltersAvailsData = () => {
    return(
        [
            {
                "value": "ctv",
                "label": "CTV"
            },
            {
                "value": "desktop",
                "label": "Desktop"
            },
            {
                "value": "laptop",
                "label": "Laptop"
            },
            {
                "value": "set-top-box",
                "label": "Set-top Box"
            },
            {
                "value": "tablet",
                "label": "Tablet"
            }
        ]
    )
}