import { Grid } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Error from "../CommonScreens/Error";
import PlatformsListing from "../../Screens/Platforms/PlatformsListing";
import PlatformDetails from "../../Screens/Platforms/PlatformDetails";
import ChannelDetails from "../../Screens/Platforms/ChannelDetails";
import DemandTagDetails from "../../Screens/Platforms/DemandTagDetails";
import ThunderstormTagDetails from "../../Screens/Platforms/ThunderstormTagDetails";
import { ApolloProvider } from "@apollo/client";
import { createApolloClient } from "../../apollo/client";
import Login from "../../Screens/Login";
import ActivityLogs from "../../Screens/ActivityLog/ActivityLogs";
import ReportDetails from "../../Screens/Reports/ReportDetails";
import AvailsSupplyDetails from "../../Screens/Reports/AvailsSupplyDetails";
import SupplyTransparencyReport from "../../Screens/Reports/SupplyTransparencyDetails";
import IntegratedSupplyTransparencyReport from "../../Screens/Reports/IntegratedSupplyTransparencyDetails";
import AdsPlusDashboard from "../../Screens/AdsPlusDashboard";
import {DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE, SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE} from "../../Constant";
import ActivityLogDetail from "../../Screens/ActivityLog/ActivityLogDetail";
import Request from "../../Screens/Requests/RequestsList/index.js";
import RequestDetails from "../../Screens/Requests/RequestDetails/index.js";
import RequestDetailsInput from "../../Screens/Requests/RequestDetailsInput";
import {RequestProvider} from "../../Context/RequestContext";
import AlertsDashboard from "../../Screens/Alerts/AlertsDashboard";
import RulesDashboard from "../../Screens/Alerts/RulesDashboard";
import AlertDetailsDashboard from "../../Screens/Alerts/AlertDetailsDashboard";
import UserManagementDashboard from "../../Screens/UserManagement/UserManagementDashboard";


function Body(props) {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Login />,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/platforms",
            element: <PlatformsListing />,
            errorElement: <Error />,
            hasErrorBoundary: true,
        },
        {
            path: "/dashboard/platform/channels/:platformId",
            element: <PlatformDetails type="channels" />,
            errorElement: <Error />,
            hasErrorBoundary: true,
        },
        {
            path: "/dashboard/platform/demand-tags/:platformId",
            element: <PlatformDetails type="demand_tags" />,
            errorElement: <Error />,
            hasErrorBoundary: true,
        },
        {
            path: "/dashboard/platform/macros/:platformId",
            element: <PlatformDetails type="macros" />,
            errorElement: <Error />,
            hasErrorBoundary: true,
        },
        {
            path: "/dashboard/channel/macros/:channelId",
            element: <ChannelDetails type="macros" />,
            errorElement: <Error />,
            hasErrorBoundary: true,
        },
        {
            path: "/dashboard/channel/thunderstorm-tags/:channelId",
            element: <ChannelDetails type="thunderstorm_tags" />,
            errorElement: <Error />,
            hasErrorBoundary: true,
        },
        {
            path: "/dashboard/channel/supply-tags/:channelId",
            element: <ChannelDetails type="supply_tags" />,
            errorElement: <Error />,
            hasErrorBoundary: true,
        },
        {
            path: "/dashboard/channel/demand-tags/:channelId",
            element: <ChannelDetails type="demand_tags" />,
            errorElement: <Error />,
            hasErrorBoundary: true,
        },
        {
            path: "/dashboard/demand-tag/macros/:demandTagId",
            element: <DemandTagDetails />,
            errorElement: <Error />,
            hasErrorBoundary: true,
        },
        {
            path: "/dashboard/thunderstorm-tags/macros/:supplyRouterId",
            element: <ThunderstormTagDetails type="macros" />,
            errorElement: <Error />,
            hasErrorBoundary: true,
        },
        {
            path: "/dashboard/thunderstorm-tags/demand-tags/:supplyRouterId",
            element: <ThunderstormTagDetails type="demand_tags" />,
            errorElement: <Error />,
            hasErrorBoundary: true,
        },
        {
            path: "/dashboard/activity-log",
            element: <ActivityLogs />,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/activity-log/:jobId",
            element: <ActivityLogDetail />,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/reports",
            element: <ReportDetails />,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/reports/avails-supply-report",
            element: <AvailsSupplyDetails />,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/reports/supply-transparency-report",
            element: <SupplyTransparencyReport />,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/reports/integrated-supply-transparency-report",
            element: <IntegratedSupplyTransparencyReport/>,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/ads-plus-dashboard/supply-health-tracker",
            element: <AdsPlusDashboard type={SUPPLY_HEALTH_ADS_PLUS_DASHBOARD_TYPE}/>,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/ads-plus-dashboard/demand-health-tracker",
            element: <AdsPlusDashboard type={DEMAND_HEALTH_ADS_PLUS_DASHBOARD_TYPE}/>,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/requests",
            element: <Request />,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/requests/view-details/:requestId",
            element: <RequestDetails />,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/requests/new-request",
            element: <RequestDetailsInput />,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/requests/edit-drafts/:requestId",
            element: <RequestDetailsInput />,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/alerts",
            element: <AlertsDashboard />,
            errorElement: <Error />,
            hasErrorBoundary: false,
        },
        {
            path: "/dashboard/alerts/rules",
            element: <RulesDashboard />,
            errorElement: <Error />,
            hasErrorBoundary: false
        },
        {
            path: "/dashboard/alerts/view-details/:alertId",
            element: <AlertDetailsDashboard />,
            errorElement: <Error />,
            hasErrorBoundary: false
        },{
            path: "/dashboard/user-management",
            element: <UserManagementDashboard />,
            errorElement: <Error />,
            hasErrorBoundary: false,
        }
    ]);
    const client = createApolloClient();

    return (
        <Grid item xs={12} className="amg-content-body">
            <Grid container className="amg-body">
                <ApolloProvider client={client}>
                    <RequestProvider>
                        <RouterProvider router={router} />
                    </RequestProvider>
                </ApolloProvider>
            </Grid>
        </Grid>
    );
}

export default Body;
