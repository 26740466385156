const PauseIcon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon-Left">
                <path id="Vector" d="M5 4.16699H6.66667V15.8337H5V4.16699ZM13.3333 4.16699H15V15.8337H13.3333V4.16699Z"
                      fill="#E7E7E7"/>
            </g>
        </svg>
    )
}

export default PauseIcon