import React, { useState } from "react";
import './index.css';
import PropTypes from "prop-types";
import { FormControl, MenuItem, Select } from "@mui/material";

const SingleSelectDropdown = (props) => {
    const [error, setError] = useState(false);

    const validate = (value) => {
        if (props?.required && !value) {
            setError(true);
        } else {
            setError(false);
        }
    };

    const handleChange = (event) => {
        const newValue = event.target.value;
        validate(newValue); // Validate on change
        props?.onChange?.(newValue);
    };

    const handleBlur = () => {
        validate(props.value); // Validate on blur using current value
    };

    return (
        <div style={{ width: props.width || "100%" }}>
            <FormControl
                error={error}
                className="custom-select-container"
                variant="standard"
                fullWidth
            >
                <Select
                    value={props.value || ""}
                    onChange={handleChange}
                    onBlur={handleBlur} // Trigger validation on blur
                    displayEmpty
                    variant="standard"
                    placeholder={props.placeholder || "Select"}
                    MenuProps={{
                        MenuListProps: {
                            sx: {
                                "& .MuiMenuItem-root.Mui-selected": {
                                    borderLeft: "2px solid #FDA560",
                                    background: "rgba(255, 255, 255, 0.08)"
                                },
                            },
                        },
                    }}
                >
                    {props.placeholder && (
                        <MenuItem value="" disabled hidden >
                            <div className="custom-placeholder" >
                                {props.placeholder || "Select"}
                            </div>
                        </MenuItem>
                    )}
                    {props?.options?.map((option) => (
                        <MenuItem
                            key={option?.value}
                            value={option?.value}
                            selected={props.value === option?.value}
                        >
                            {option.customContent ? ( option.customContent ) : ( option.label)}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {/* Custom Helper Text */}
            {error && (
                <div className="custom-helper-text" >
                    {props?.errorMessage || "This field is required."}
                </div>
            )}
        </div>
    );
};

SingleSelectDropdown.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.string.isRequired,
        })
    ).isRequired,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    width: PropTypes.string,
};

export default SingleSelectDropdown;
