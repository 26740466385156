export const reportData = [
    {
        reportName: "Avails report for Jan 2024",
        reportType: "Avails and Supply list",
        createdOn: "12-09-2023"
    },
    {
        reportName: "Performance report for Q1 2024",
        reportType: "Performance Analysis",
        createdOn: "03-21-2023"
    },
    {
        reportName: "Financial report for FY 2023",
        reportType: "Financial Statements",
        createdOn: "07-05-2023"
    },
    {
        reportName: "Customer Feedback Report - Jan 2024",
        reportType: "Customer Satisfaction Analysis",
        createdOn: "02-15-2023"
    },
    {
        reportName: "Marketing Campaign Analysis - Q2 2024",
        reportType: "Marketing Analysis",
        createdOn: "05-10-2023"
    },
    {
        reportName: "Product Development Report - Jan 2024",
        reportType: "Product Development Analysis",
        createdOn: "01-28-2023"
    }
];
