const FilterIconLog = (props) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path
                d="M17.5 2.5V4.16667H16.6667L12.5 10.4167V17.5H7.5V10.4167L3.33333 4.16667H2.5V2.5H17.5ZM5.33667 4.16667L9.16667 9.91167V15.8333H10.8333V9.91167L14.6633 4.16667H5.33667Z"
                fill="#E7E7E7"/>
        </svg>
    )
}

export default FilterIconLog;