import axios from "axios";
import {BFF_LAYER_URI, MAX_LIMIT_SHOW_TABLE_DATA} from "../Constant";
import {adsHeader} from "../utils/helper";

export const getAmagiFridayUsers = (offSet = 0, limit = MAX_LIMIT_SHOW_TABLE_DATA) => {
    let data = JSON.stringify({
        query: `query MyQuery {
                    getAllAmagiFridayUsers(offset: ${offSet}, limit: ${limit}) {
                        data {
                            user_name
                            full_name
                            user_roles
                            invitation_id
                            created_on
                            updated_on
                            user_status
                            last_login_at
                        }
                        total_count
                    }
        }`,
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    return axios.request(config)
        .then((response) => {
            if (response?.data?.data && response.status === 200) {
                return response.data.data.getAllAmagiFridayUsers;
            } else {
                return [];
            }
        })
        .catch((_) => {
            return null;
        });
};


