const LinkIcon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Icon-Left">
                <path id="Vector"
                      d="M14.7141 12.3568L13.5357 11.1785L14.7141 10.0002C15.0236 9.69063 15.2691 9.32316 15.4367 8.91873C15.6042 8.5143 15.6904 8.08083 15.6904 7.64308C15.6904 7.20533 15.6042 6.77187 15.4367 6.36744C15.2691 5.96301 15.0236 5.59554 14.7141 5.286C14.4045 4.97646 14.037 4.73092 13.6326 4.5634C13.2282 4.39588 12.7947 4.30966 12.357 4.30966C11.9192 4.30966 11.4858 4.39588 11.0813 4.5634C10.6769 4.73092 10.3094 4.97646 9.99989 5.286L8.82156 6.46433L7.64322 5.286L8.82156 4.10767C9.76182 3.1826 11.0295 2.66655 12.3485 2.67192C13.6676 2.67729 14.931 3.20365 15.8637 4.13634C16.7964 5.06904 17.3228 6.3325 17.3281 7.65152C17.3335 8.97053 16.8175 10.2382 15.8924 11.1785L14.7141 12.3568V12.3568ZM12.3566 14.7143L11.1782 15.8927C10.7153 16.3632 10.1638 16.7374 9.55561 16.9937C8.94737 17.25 8.2944 17.3833 7.63437 17.386C6.97434 17.3887 6.32031 17.2607 5.71001 17.0093C5.0997 16.758 4.5452 16.3883 4.07848 15.9216C3.61176 15.4549 3.24207 14.9004 2.99073 14.29C2.73939 13.6797 2.61137 13.0257 2.61406 12.3657C2.61674 11.7057 2.75008 11.0527 3.00639 10.4444C3.26269 9.83621 3.63689 9.28473 4.10739 8.82183L5.28572 7.6435L6.46406 8.82183L5.28572 10.0002C4.97619 10.3097 4.73065 10.6772 4.56313 11.0816C4.39561 11.486 4.30939 11.9195 4.30939 12.3572C4.30939 12.795 4.39561 13.2285 4.56313 13.6329C4.73065 14.0373 4.97619 14.4048 5.28572 14.7143C5.59526 15.0239 5.96273 15.2694 6.36716 15.4369C6.77159 15.6044 7.20506 15.6907 7.64281 15.6907C8.08056 15.6907 8.51402 15.6044 8.91845 15.4369C9.32288 15.2694 9.69035 15.0239 9.99989 14.7143L11.1782 13.536L12.3566 14.7143ZM12.3566 6.46433L13.5357 7.6435L7.64322 13.5352L6.46406 12.3568L12.3566 6.46517V6.46433Z"
                      fill="#E7E7E7"/>
            </g>
        </svg>
    )
}

export default LinkIcon