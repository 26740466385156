const NoAlertRulesIcon = () => {

    return (
        <svg width="407" height="210" viewBox="0 0 407 210" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="70.5605" y="20.1012" width="271.539" height="120.615" rx="7.11321" stroke="white"
                  stroke-opacity="0.28" stroke-width="0.61854" stroke-dasharray="7.42 7.42"/>
            <rect x="192.104" y="8.03925" width="26.2879" height="23.0406" fill="#1E1E1E"/>
            <path
                d="M207.2 26.9473V29.4737H212V32H200V29.4737H204.8V26.9473H195.2C194.882 26.9473 194.577 26.8143 194.352 26.5774C194.127 26.3405 194 26.0192 194 25.6842V9.26313C194 8.92812 194.127 8.60684 194.352 8.36995C194.577 8.13306 194.882 7.99998 195.2 7.99998H216.8C217.119 7.99998 217.424 8.13306 217.649 8.36995C217.874 8.60684 218 8.92812 218 9.26313V25.6842C218 26.0192 217.874 26.3405 217.649 26.5774C217.424 26.8143 217.119 26.9473 216.8 26.9473H207.2ZM196.4 10.5263V24.421H215.6V10.5263H196.4ZM203.6 13.6842L209.6 17.4737L203.6 21.2631V13.6842Z"
                fill="#64686C"/>
            <path
                d="M55.6638 69.6167C54.4449 71.4727 53.6255 73.5116 53.1885 75.6143L49.8394 75.9295V82.4155L53.1888 82.7313C53.6249 84.8334 54.4449 86.8723 55.6638 88.7289L53.5245 91.3127L58.111 95.8991L60.6949 93.7601C62.5512 94.9787 64.5899 95.7987 66.6926 96.2351L67.0081 99.5845H73.4942L73.8097 96.2351C75.9125 95.7981 77.9511 94.9784 79.8074 93.7598L82.3913 95.8988L86.9778 91.3124L84.8388 88.7286C86.0574 86.8723 86.8772 84.8337 87.3135 82.731L90.6624 82.4155L90.663 75.9295L87.3138 75.6137C86.8772 73.5113 86.0571 71.4727 84.8385 69.6164L86.9778 67.0326L82.3913 62.4462L79.8074 64.5852C77.9511 63.3663 75.9122 62.5463 73.8097 62.1099L73.494 58.7608H67.0078L66.6923 62.1102C64.5896 62.5469 62.5509 63.3663 60.6949 64.5852L58.111 62.4459L53.5245 67.0323L55.6638 69.6161V69.6167Z"
                fill="#1659C5"/>
            <path
                d="M70.2507 67.9464C64.0505 67.9464 59.0242 72.9727 59.0242 79.1729C59.0242 85.373 64.0505 90.3994 70.2507 90.3994C76.4509 90.3994 81.4772 85.373 81.4772 79.1729C81.4772 72.9727 76.4509 67.9464 70.2507 67.9464ZM70.2507 72.0628C70.664 72.0628 70.9991 72.3978 70.9991 72.8112V78.7402L75.4897 81.3363C75.8477 81.543 75.9654 81.9957 75.7587 82.3537C75.5519 82.7117 75.0993 82.8294 74.7413 82.6227C73.1217 81.6881 71.4902 80.7467 69.8765 79.8161C69.6531 79.6866 69.5022 79.4496 69.5022 79.1729V72.8112C69.5022 72.3978 69.8373 72.0628 70.2507 72.0628Z"
                fill="#19191A"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M204.583 60.6965C202.223 60.9192 200.72 61.6345 199.139 63.2875C197.637 64.8587 197.073 66.6282 196.96 70.1308L196.857 73.3178L195.075 73.8834C185.254 77.0001 178.599 83.6444 175.787 93.1384C174.988 95.8385 174.842 96.663 174.353 101.239C174.244 102.261 173.866 105.531 173.513 108.504C172.918 113.518 170.498 135.634 169.83 142.161C169.819 142.273 166.73 144.769 162.967 147.706C153.34 155.22 152.905 155.667 152.43 158.524C151.944 161.455 153.331 164.597 155.78 166.11L156.969 166.845L206.081 166.851C255.127 166.856 255.195 166.855 256.567 166.155C258.948 164.941 260.043 162.85 260.068 159.465C260.09 156.679 259.034 154.788 256.469 153.021C252.508 150.291 242.793 142.339 242.79 141.824C242.786 141.128 241.973 133.753 241.109 126.581C240.156 118.674 239.885 116.37 238.743 106.477C238.185 101.645 237.504 96.5194 237.229 95.0867C235.366 85.3926 228.131 77.4363 218.128 74.0831L215.593 73.2336L215.416 69.5817C215.221 65.5784 214.876 64.6124 213.003 62.8296C211.049 60.9685 208.511 60.3255 204.583 60.6965ZM205.457 66.3211C205.085 66.3991 204.465 66.5295 204.078 66.6106C203.117 66.8127 202.585 68.142 202.585 70.3382V72.1808H206.301H210.018V70.1382C210.018 68.4197 209.887 67.9636 209.189 67.2661C208.733 66.81 208.061 66.4366 207.696 66.4366C207.331 66.4366 206.83 66.3785 206.583 66.3079C206.335 66.2369 205.828 66.243 205.457 66.3211Z"
                  fill="#46484A"/>
            <path opacity="0.825"
                  d="M156.021 92.7286H152.356V89.0639C152.356 88.9899 152.342 88.9165 152.314 88.8481C152.285 88.7797 152.244 88.7175 152.191 88.6652C152.139 88.6128 152.077 88.5713 152.008 88.5429C151.94 88.5146 151.867 88.5 151.793 88.5C151.719 88.5 151.645 88.5146 151.577 88.5429C151.508 88.5713 151.446 88.6128 151.394 88.6652C151.341 88.7175 151.3 88.7797 151.272 88.8481C151.243 88.9165 151.229 88.9899 151.229 89.0639V92.7286H147.564C147.49 92.7286 147.417 92.7432 147.348 92.7716C147.28 92.7999 147.218 92.8414 147.165 92.8938C147.113 92.9462 147.071 93.0083 147.043 93.0768C147.015 93.1452 147 93.2185 147 93.2926C147 93.3666 147.015 93.44 147.043 93.5084C147.071 93.5768 147.113 93.639 147.165 93.6913C147.218 93.7437 147.28 93.7852 147.348 93.8136C147.417 93.8419 147.49 93.8565 147.564 93.8565H151.229V97.5212C151.229 97.5953 151.243 97.6686 151.272 97.737C151.3 97.8054 151.341 97.8676 151.394 97.92C151.446 97.9723 151.508 98.0139 151.577 98.0422C151.645 98.0706 151.719 98.0851 151.793 98.0851C151.867 98.0851 151.94 98.0706 152.008 98.0422C152.077 98.0139 152.139 97.9723 152.191 97.92C152.244 97.8676 152.285 97.8054 152.314 97.737C152.342 97.6686 152.356 97.5953 152.356 97.5212V93.8565H156.021C156.095 93.8565 156.169 93.8419 156.237 93.8136C156.305 93.7852 156.368 93.7437 156.42 93.6913C156.472 93.639 156.514 93.5768 156.542 93.5084C156.571 93.44 156.585 93.3666 156.585 93.2926C156.585 93.2185 156.571 93.1452 156.542 93.0768C156.514 93.0083 156.472 92.9462 156.42 92.8938C156.368 92.8414 156.305 92.7999 156.237 92.7716C156.169 92.7432 156.095 92.7286 156.021 92.7286Z"
                  fill="#686868"/>
            <path
                d="M256.518 118.024H253.463V114.97C253.463 114.845 253.414 114.726 253.326 114.638C253.238 114.549 253.118 114.5 252.994 114.5C252.869 114.5 252.75 114.549 252.662 114.638C252.574 114.726 252.524 114.845 252.524 114.97V118.024H249.47C249.345 118.024 249.226 118.074 249.138 118.162C249.049 118.25 249 118.369 249 118.494C249 118.618 249.049 118.738 249.138 118.826C249.226 118.914 249.345 118.963 249.47 118.963H252.524V122.018C252.524 122.143 252.574 122.262 252.662 122.35C252.75 122.438 252.869 122.488 252.994 122.488C253.118 122.488 253.238 122.438 253.326 122.35C253.414 122.262 253.463 122.143 253.463 122.018V118.963H256.518C256.643 118.963 256.762 118.914 256.85 118.826C256.938 118.738 256.988 118.618 256.988 118.494C256.988 118.369 256.938 118.25 256.85 118.162C256.762 118.074 256.643 118.024 256.518 118.024Z"
                fill="#686868"/>
            <path opacity="0.835"
                  d="M139.535 134.187C140.234 134.051 140.958 134.126 141.615 134.401C142.272 134.677 142.832 135.141 143.225 135.735C143.618 136.329 143.827 137.026 143.823 137.738C143.82 138.45 143.606 139.146 143.208 139.736C142.81 140.327 142.245 140.786 141.586 141.055C140.927 141.325 140.202 141.393 139.504 141.251C138.806 141.109 138.166 140.763 137.665 140.258C137.163 139.752 136.823 139.109 136.687 138.409C136.505 137.472 136.703 136.5 137.237 135.708C137.771 134.917 138.598 134.369 139.535 134.187V134.187Z"
                  fill="#3B82F6"/>
            <path
                d="M274.396 148.293C275.72 148.293 276.793 147.22 276.793 145.896C276.793 144.573 275.72 143.5 274.396 143.5C273.073 143.5 272 144.573 272 145.896C272 147.22 273.073 148.293 274.396 148.293Z"
                fill="#3B82F6"/>
            <path
                d="M251 71.5C252.657 71.5 254 70.1569 254 68.5C254 66.8431 252.657 65.5 251 65.5C249.343 65.5 248 66.8431 248 68.5C248 70.1569 249.343 71.5 251 71.5Z"
                fill="#3B82F6"/>
            <path
                d="M194.908 182.182C193.227 179.929 191.44 174.348 192.167 173.621C192.271 173.516 198.449 173.471 205.896 173.519L219.436 173.608L219.357 175.186C219.178 178.756 216.576 183.243 213.46 185.357C210.888 187.102 208.337 187.745 204.771 187.546C200.408 187.303 197.582 185.766 194.908 182.182Z"
                fill="#3B82F6"/>
            <line x1="281.263" y1="65.736" x2="130.236" y2="200.737" stroke="#64686C" stroke-width="6"
                  stroke-linecap="round"/>
            <rect x="328.337" y="66.7237" width="28.1436" height="24.8962" fill="#1E1E1E"/>
            <path
                d="M347.132 77.5121L338.821 71.9726C338.718 71.9042 338.599 71.8648 338.476 71.8588C338.353 71.8528 338.23 71.8802 338.122 71.9382C338.013 71.9963 337.922 72.0827 337.858 72.1883C337.795 72.294 337.761 72.4148 337.761 72.5381V83.6207C337.761 83.7436 337.795 83.8642 337.859 83.9696C337.922 84.0749 338.013 84.1612 338.121 84.2192C338.23 84.2772 338.352 84.3047 338.475 84.2989C338.597 84.2931 338.716 84.2542 338.819 84.1862L347.132 78.6466C347.225 78.5844 347.302 78.5001 347.355 78.4011C347.408 78.3022 347.436 78.1916 347.436 78.0794C347.436 77.9671 347.408 77.8566 347.355 77.7576C347.302 77.6587 347.225 77.5743 347.132 77.5121Z"
                fill="#64686C"/>
            <path
                d="M352.839 80.5388C351.641 85.7243 347.049 89.5859 341.568 89.5859C335.175 89.5859 329.992 84.3326 329.992 77.8524C329.992 71.3722 335.175 66.119 341.568 66.119C346.117 66.119 350.054 68.7789 351.946 72.6481L353.602 71.095C353.638 71.1346 353.674 71.1745 353.709 71.2147C351.378 66.8428 346.815 63.8728 341.568 63.8728C333.951 63.8728 327.776 70.1317 327.776 77.8524C327.776 85.5731 333.951 91.832 341.568 91.832C347.998 91.832 353.4 87.3722 354.928 81.338L352.839 80.5388Z"
                fill="#64686C"/>
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M355.736 68.1271L354.143 73.8949L348.307 73.0462L348.622 70.8228L352.533 71.3916L353.602 67.5218L355.736 68.1271Z"
                  fill="#64686C"/>
            <path d="M4.06738 210H406.118V208.139H205.093H4.06738" fill="#363F4C" fill-opacity="0.5"/>
        </svg>
    )
}

export default NoAlertRulesIcon;