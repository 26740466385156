const JiraTicketIcon = (props) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="ticket-line" opacity="0.5">
                <path id="Vector"
                      d="M17.5001 2.5C17.7211 2.5 17.9331 2.5878 18.0893 2.74408C18.2456 2.90036 18.3334 3.11232 18.3334 3.33333V7.91667C17.7809 7.91667 17.251 8.13616 16.8603 8.52686C16.4696 8.91756 16.2501 9.44747 16.2501 10C16.2501 10.5525 16.4696 11.0824 16.8603 11.4731C17.251 11.8638 17.7809 12.0833 18.3334 12.0833V16.6667C18.3334 16.8877 18.2456 17.0996 18.0893 17.2559C17.9331 17.4122 17.7211 17.5 17.5001 17.5H2.50008C2.27907 17.5 2.06711 17.4122 1.91083 17.2559C1.75455 17.0996 1.66675 16.8877 1.66675 16.6667V12.0833C2.21928 12.0833 2.74919 11.8638 3.13989 11.4731C3.53059 11.0824 3.75008 10.5525 3.75008 10C3.75008 9.44747 3.53059 8.91756 3.13989 8.52686C2.74919 8.13616 2.21928 7.91667 1.66675 7.91667V3.33333C1.66675 3.11232 1.75455 2.90036 1.91083 2.74408C2.06711 2.5878 2.27907 2.5 2.50008 2.5H17.5001ZM16.6667 4.16667H3.33341V6.64L3.46341 6.7075C4.02868 7.01587 4.50502 7.4646 4.84655 8.01047C5.18809 8.55634 5.38331 9.18096 5.41341 9.82417L5.41675 10C5.41685 10.6735 5.23551 11.3347 4.89179 11.9139C4.54807 12.4931 4.05466 12.969 3.46341 13.2917L3.33341 13.36V15.8333H16.6667V13.3592L16.5367 13.2925C15.9715 12.9841 15.4951 12.5354 15.1536 11.9895C14.8121 11.4437 14.6169 10.819 14.5867 10.1758L14.5834 10C14.5834 8.58 15.3726 7.34417 16.5367 6.70833L16.6667 6.63917V4.16667Z"
                      fill="#E7E7E7"/>
            </g>
        </svg>
    )
}

export default JiraTicketIcon