export const AlertsTypeIcons = (type="") => {
    if (type.toLowerCase() === "trend"){
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="pulse-line">
                    <path id="Vector"
                          d="M5.99996 5.02596L9.99996 14.3593L12.4393 8.66662H15.3333V7.33329H11.5606L9.99996 10.974L5.99996 1.64062L3.56063 7.33329H0.666626V8.66662H4.43929L5.99996 5.02596Z"
                          fill="#CFDD41"/>
                </g>
            </svg>
        )
    } else if (type.toLowerCase() === "spot") {
        return (
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="focus-line">
                    <path id="Vector"
                          d="M8.00004 13.3335C9.41453 13.3335 10.7711 12.7716 11.7713 11.7714C12.7715 10.7712 13.3334 9.41465 13.3334 8.00016C13.3334 6.58567 12.7715 5.22912 11.7713 4.22893C10.7711 3.22873 9.41453 2.66683 8.00004 2.66683C6.58555 2.66683 5.229 3.22873 4.2288 4.22893C3.22861 5.22912 2.66671 6.58567 2.66671 8.00016C2.66671 9.41465 3.22861 10.7712 4.2288 11.7714C5.229 12.7716 6.58555 13.3335 8.00004 13.3335V13.3335ZM8.00004 14.6668C4.31804 14.6668 1.33337 11.6822 1.33337 8.00016C1.33337 4.31816 4.31804 1.3335 8.00004 1.3335C11.682 1.3335 14.6667 4.31816 14.6667 8.00016C14.6667 11.6822 11.682 14.6668 8.00004 14.6668ZM8.00004 9.3335C7.64642 9.3335 7.30728 9.19302 7.05723 8.94297C6.80718 8.69292 6.66671 8.35378 6.66671 8.00016C6.66671 7.64654 6.80718 7.3074 7.05723 7.05735C7.30728 6.80731 7.64642 6.66683 8.00004 6.66683C8.35366 6.66683 8.6928 6.80731 8.94285 7.05735C9.1929 7.3074 9.33337 7.64654 9.33337 8.00016C9.33337 8.35378 9.1929 8.69292 8.94285 8.94297C8.6928 9.19302 8.35366 9.3335 8.00004 9.3335Z"
                          fill="#3DE1B1"/>
                </g>
            </svg>
        )
    } else {
        return (
            <></>
        )
    }
}
