import axios from "axios";
import {adsHeader} from "../utils/helper";
import {BFF_LAYER_URI} from "../Constant";

export const updateAmagiFridayUserStatus = (userName) => {
    let data = JSON.stringify({
        query: `mutation myMutation{
                updateAmagiFridayUserStatus(updateAmagiFridayUserStatus: {user_name: "${userName}"}){
                    user_name
                    message
                }
        }`,
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    // return data;

    return axios.request(config)
        .then((response) => {
            if (response?.data?.data && response.status === 200) {
                return response.data.data;
            } else {
                return [];
            }
        })
        .catch((_) => {
            return null;
        });
};


