export const AVAILS_DATA = [
    {
        id: 1,
        inventory_ownership: 'Company A',
        supply_partner: 'Partner X',
        channel: 'Channel 1',
        device_type: 'Mobile',
        country: 'USA',
        region: 'North America',
        monthly_30s: 10000,
        floor_price: 0.5,
        average_price: 1.2
    },
    {
        id: 2,
        inventory_ownership: 'Company B',
        supply_partner: 'Partner Y',
        channel: 'Channel 2',
        device_type: 'Desktop',
        country: 'UK',
        region: 'Europe',
        monthly_30s: 8000,
        floor_price: 0.6,
        average_price: 1.1
    },
    {
        id: 3,
        inventory_ownership: 'Company C',
        supply_partner: 'Partner Z',
        channel: 'Channel 3',
        device_type: 'Tablet',
        country: 'Canada',
        region: 'North America',
        monthly_30s: 12000,
        floor_price: 0.4,
        average_price: 1.3
    },
    {
        id: 4,
        inventory_ownership: 'Company D',
        supply_partner: 'Partner W',
        channel: 'Channel 4',
        device_type: 'Mobile',
        country: 'Australia',
        region: 'Australia',
        monthly_30s: 9000,
        floor_price: 0.7,
        average_price: 1.0
    },
    {
        id: 5,
        inventory_ownership: 'Company E',
        supply_partner: 'Partner V',
        channel: 'Channel 5',
        device_type: 'Desktop',
        country: 'Germany',
        region: 'Europe',
        monthly_30s: 11000,
        floor_price: 0.6,
        average_price: 1.2
    },
    {
        id: 6,
        inventory_ownership: 'Company F',
        supply_partner: 'Partner U',
        channel: 'Channel 6',
        device_type: 'Mobile',
        country: 'France',
        region: 'Europe',
        monthly_30s: 9500,
        floor_price: 0.5,
        average_price: 1.1
    },
    {
        id: 7,
        inventory_ownership: 'Company G',
        supply_partner: 'Partner T',
        channel: 'Channel 7',
        device_type: 'Tablet',
        country: 'Brazil',
        region: 'South America',
        monthly_30s: 10500,
        floor_price: 0.6,
        average_price: 1.3
    },
    {
        id: 8,
        inventory_ownership: 'Company H',
        supply_partner: 'Partner S',
        channel: 'Channel 8',
        device_type: 'Desktop',
        country: 'Japan',
        region: 'Asia',
        monthly_30s: 8800,
        floor_price: 0.4,
        average_price: 1.0
    },
    {
        id: 9,
        inventory_ownership: 'Company I',
        supply_partner: 'Partner R',
        channel: 'Channel 9',
        device_type: 'Mobile',
        country: 'India',
        region: 'Asia',
        monthly_30s: 11500,
        floor_price: 0.7,
        average_price: 1.2
    },
    {
        id: 10,
        inventory_ownership: 'Company J',
        supply_partner: 'Partner Q',
        channel: 'Channel 10',
        device_type: 'Tablet',
        country: 'Mexico',
        region: 'North America',
        monthly_30s: 10000,
        floor_price: 0.5,
        average_price: 1.1
    },
    {
        id: 11,
        inventory_ownership: 'Company K',
        supply_partner: 'Partner P',
        channel: 'Channel 11',
        device_type: 'Mobile',
        country: 'China',
        region: 'Asia',
        monthly_30s: 9200,
        floor_price: 0.6,
        average_price: 1.3
    },
    {
        id: 12,
        inventory_ownership: 'Company L',
        supply_partner: 'Partner O',
        channel: 'Channel 12',
        device_type: 'Desktop',
        country: 'Spain',
        region: 'Europe',
        monthly_30s: 9800,
        floor_price: 0.5,
        average_price: 1.2
    },
    {
        id: 13,
        inventory_ownership: 'Company M',
        supply_partner: 'Partner N',
        channel: 'Channel 13',
        device_type: 'Mobile',
        country: 'Russia',
        region: 'Europe',
        monthly_30s: 10200,
        floor_price: 0.4,
        average_price: 1.1
    },
    {
        id: 14,
        inventory_ownership: 'Company N',
        supply_partner: 'Partner M',
        channel: 'Channel 14',
        device_type: 'Tablet',
        country: 'South Africa',
        region: 'Africa',
        monthly_30s: 9300,
        floor_price: 0.7,
        average_price: 1.0
    },
    {
        id: 15,
        inventory_ownership: 'Company O',
        supply_partner: 'Partner L',
        channel: 'Channel 15',
        device_type: 'Desktop',
        country: 'Italy',
        region: 'Europe',
        monthly_30s: 10700,
        floor_price: 0.6,
        average_price: 1.2
    },
    {
        id: 16,
        inventory_ownership: 'Company P',
        supply_partner: 'Partner K',
        channel: 'Channel 16',
        device_type: 'Mobile',
        country: 'Argentina',
        region: 'South America',
        monthly_30s: 9400,
        floor_price: 0.5,
        average_price: 1.1
    },
    {
        id: 17,
        inventory_ownership: 'Company Q',
        supply_partner: 'Partner J',
        channel: 'Channel 17',
        device_type: 'Tablet',
        country: 'Netherlands',
        region: 'Europe',
        monthly_30s: 9900,
        floor_price: 0.4,
        average_price: 1.3
    },
    {
        id: 18,
        inventory_ownership: 'Company R',
        supply_partner: 'Partner I',
        channel: 'Channel 18',
        device_type: 'Mobile',
        country: 'Switzerland',
        region: 'Europe',
        monthly_30s: 9600,
        floor_price: 0.6,
        average_price: 1.2
    },
    {
        id: 19,
        inventory_ownership: 'Company S',
        supply_partner: 'Partner H',
        channel: 'Channel 19',
        device_type: 'Desktop',
        country: 'Sweden',
        region: 'Europe',
        monthly_30s: 10300,
        floor_price: 0.5,
        average_price: 1.1
    },
    {
        id: 20,
        inventory_ownership: 'Company T',
        supply_partner: 'Partner G',
        channel: 'Channel 20',
        device_type: 'Mobile',
        country: 'Norway',
        region: 'Europe',
        monthly_30s: 11000,
        floor_price: 0.4,
        average_price: 1.0
    }
];
