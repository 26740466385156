import React from "react";
import PropTypes from "prop-types";

function AlertDetailsBreadCrumbs (props) {
    return(
        <React.Fragment>
            <span
                onClick={() => props.onClickBreadCrumbsLink(
                    "/dashboard/alerts"
                )}
                className="amg-bread-crumbs-links"
            >
                Alerts &nbsp;
            </span>/ &nbsp;<span> {props.alertName} </span>
        </React.Fragment>
    )
}

AlertDetailsBreadCrumbs.propTypes = {
    onClickBreadCrumbsLink: PropTypes.func,
    platformName: PropTypes.string,
    channelId: PropTypes.string
}

export default AlertDetailsBreadCrumbs