import CrossIcon from "../../assests/icons/crossIcon";

export const AddUsersFormConfig = ( ) => [
    {
        label: "Add user",
        editLabel: "Change role",
        type: "header",
        containsIcon: true,
        iconAsset: <CrossIcon />
    },
    {
        type: "vector",
    },
    {
        label: "Email ID",
        type: "string",
        key: "user_name",
        isRequired: true,
        isMutable: false,
        placeholder: "Enter amagi email ID",
        areOptionsDependent: false,
        validation: {
            type: "required",
            errorMessage: "Email ID is required.",
            validationPattern: "^[a-zA-Z0-9._%+-]+@amagi.com$",
            validationErrorMessage: "Invalid amagi email ID.",
            maxLength: 75,
        },
        inputWidth: "280px",
    },
    {
        label: "Name",
        type: "string",
        key: "full_name",
        isRequired: true,
        isMutable: false,
        placeholder: "Enter full name",
        validation: {
            type: "required",
            errorMessage: "Name is required.",
            maxLength: 30,
        },
        inputWidth: "280px",
    },
    {
        label: "Role",
        type: "simple_select",
        key: "user_roles",
        isRequired: true,
        isMutable: true,
        placeholder: "Select role",
        validation: {
            type: "required",
            errorMessage: "Role is required.",
        },
        inputWidth: "280px",
        options: [
            {
                label: "Admin",
                value: "Admin",
            },
            {
                label: "Adops",
                value: "Adops",
            },
            {
                label: "AdsPlus",
                value: "AdsPlus",
            },
            {
                label: "Onboarding",
                value: "Onboarding",
            }
        ]
    },
]