

const BellIcon = (props) => {
    return(
        <svg width="174" height="174" viewBox="0 0 174 174" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="&#226;&#156;&#168; bell icon">
                <path id="Vector" fill-rule="evenodd" clip-rule="evenodd"
                      d="M85.5833 24.6965C83.2228 24.9192 81.7202 25.6345 80.1392 27.2875C78.6366 28.8587 78.073 30.6282 77.9598 34.1308L77.8567 37.3178L76.0748 37.8834C66.254 41.0001 59.5985 47.6444 56.7873 57.1384C55.9878 59.8385 55.8418 60.663 55.3528 65.2394C55.2437 66.2615 54.866 69.5306 54.5132 72.504C53.9182 77.5183 51.4983 99.6343 50.8303 106.161C50.8188 106.273 47.7301 108.769 43.967 111.706C34.3398 119.22 33.9046 119.667 33.4302 122.524C32.944 125.455 34.3314 128.597 36.7797 130.11L37.9694 130.845L87.0809 130.851C136.127 130.856 136.195 130.855 137.567 130.155C139.948 128.941 141.043 126.85 141.068 123.465C141.09 120.679 140.034 118.788 137.469 117.021C133.508 114.291 123.793 106.339 123.79 105.824C123.786 105.128 122.973 97.7529 122.109 90.5812C121.156 82.6738 120.885 80.3704 119.743 70.4767C119.185 65.6448 118.504 60.5194 118.229 59.0867C116.366 49.3926 109.131 41.4363 99.1276 38.0831L96.5934 37.2336L96.4161 33.5817C96.2214 29.5784 95.8764 28.6124 94.0035 26.8296C92.0488 24.9685 89.5109 24.3255 85.5833 24.6965ZM86.4567 30.3211C86.085 30.3991 85.465 30.5295 85.0784 30.6106C84.1171 30.8127 83.5847 32.142 83.5847 34.3382V36.1808H87.3014H91.0182V34.1382C91.0182 32.4197 90.8865 31.9636 90.1888 31.2661C89.7326 30.81 89.0609 30.4366 88.696 30.4366C88.3311 30.4366 87.8299 30.3785 87.5826 30.3079C87.3349 30.2369 86.8284 30.243 86.4567 30.3211Z"
                      fill="#46484A"/>
                <path id="Path 5565" opacity="0.825"
                      d="M37.0212 56.7286H33.3565V53.0639C33.3565 52.9899 33.3419 52.9165 33.3136 52.8481C33.2852 52.7797 33.2437 52.7175 33.1913 52.6652C33.139 52.6128 33.0768 52.5713 33.0084 52.5429C32.94 52.5146 32.8666 52.5 32.7926 52.5C32.7185 52.5 32.6452 52.5146 32.5768 52.5429C32.5083 52.5713 32.4462 52.6128 32.3938 52.6652C32.3414 52.7175 32.2999 52.7797 32.2716 52.8481C32.2432 52.9165 32.2286 52.9899 32.2286 53.0639V56.7286H28.5639C28.4899 56.7286 28.4165 56.7432 28.3481 56.7716C28.2797 56.7999 28.2175 56.8414 28.1652 56.8938C28.1128 56.9462 28.0713 57.0083 28.0429 57.0768C28.0146 57.1452 28 57.2185 28 57.2926C28 57.3666 28.0146 57.44 28.0429 57.5084C28.0713 57.5768 28.1128 57.639 28.1652 57.6913C28.2175 57.7437 28.2797 57.7852 28.3481 57.8136C28.4165 57.8419 28.4899 57.8565 28.5639 57.8565H32.2286V61.5212C32.2286 61.5953 32.2432 61.6686 32.2716 61.737C32.2999 61.8054 32.3414 61.8676 32.3938 61.92C32.4462 61.9723 32.5083 62.0139 32.5768 62.0422C32.6452 62.0706 32.7185 62.0851 32.7926 62.0851C32.8666 62.0851 32.94 62.0706 33.0084 62.0422C33.0768 62.0139 33.139 61.9723 33.1913 61.92C33.2437 61.8676 33.2852 61.8054 33.3136 61.737C33.3419 61.6686 33.3565 61.5953 33.3565 61.5212V57.8565H37.0212C37.0953 57.8565 37.1686 57.8419 37.237 57.8136C37.3054 57.7852 37.3676 57.7437 37.42 57.6913C37.4723 57.639 37.5139 57.5768 37.5422 57.5084C37.5706 57.44 37.5851 57.3666 37.5851 57.2926C37.5851 57.2185 37.5706 57.1452 37.5422 57.0768C37.5139 57.0083 37.4723 56.9462 37.42 56.8938C37.3676 56.8414 37.3054 56.7999 37.237 56.7716C37.1686 56.7432 37.0953 56.7286 37.0212 56.7286Z"
                      fill="#686868"/>
                <path id="Path 5501"
                      d="M137.518 82.0241H134.463V78.9697C134.463 78.8451 134.414 78.7256 134.326 78.6376C134.238 78.5495 134.118 78.5 133.994 78.5C133.869 78.5 133.75 78.5495 133.662 78.6376C133.574 78.7256 133.524 78.8451 133.524 78.9697V82.0241H130.47C130.345 82.0241 130.226 82.0736 130.138 82.1617C130.049 82.2498 130 82.3692 130 82.4938C130 82.6184 130.049 82.7378 130.138 82.8259C130.226 82.914 130.345 82.9635 130.47 82.9635H133.524V86.0179C133.524 86.1425 133.574 86.262 133.662 86.3501C133.75 86.4381 133.869 86.4876 133.994 86.4876C134.118 86.4876 134.238 86.4381 134.326 86.3501C134.414 86.262 134.463 86.1425 134.463 86.0179V82.9635H137.518C137.643 82.9635 137.762 82.914 137.85 82.8259C137.938 82.7378 137.988 82.6184 137.988 82.4938C137.988 82.3692 137.938 82.2498 137.85 82.1617C137.762 82.0736 137.643 82.0241 137.518 82.0241Z"
                      fill="#686868"/>
                <path id="Path 5564" opacity="0.835"
                      d="M20.5351 98.1871C21.2343 98.0512 21.958 98.1257 22.6149 98.4012C23.2717 98.6766 23.8321 99.1406 24.2253 99.7346C24.6184 100.329 24.8266 101.026 24.8234 101.738C24.8203 102.45 24.6061 103.146 24.2078 103.736C23.8095 104.327 23.2451 104.786 22.5858 105.055C21.9266 105.325 21.2022 105.393 20.5043 105.251C19.8063 105.109 19.1661 104.763 18.6647 104.258C18.1632 103.752 17.8231 103.109 17.6872 102.409C17.5049 101.472 17.7026 100.5 18.2367 99.7084C18.7708 98.9166 19.5975 98.3694 20.5351 98.1871V98.1871Z"
                      fill="#3B82F6"/>
                <path id="Ellipse 89"
                      d="M155.396 112.293C156.72 112.293 157.793 111.22 157.793 109.896C157.793 108.573 156.72 107.5 155.396 107.5C154.073 107.5 153 108.573 153 109.896C153 111.22 154.073 112.293 155.396 112.293Z"
                      fill="#3B82F6"/>
                <path id="Ellipse 90"
                      d="M132 35.5C133.657 35.5 135 34.1569 135 32.5C135 30.8431 133.657 29.5 132 29.5C130.343 29.5 129 30.8431 129 32.5C129 34.1569 130.343 35.5 132 35.5Z"
                      fill="#3B82F6"/>
                <path id="Vector_2"
                      d="M75.9079 146.182C74.2269 143.929 72.4398 138.348 73.1666 137.621C73.2706 137.516 79.449 137.471 86.8961 137.519L100.436 137.608L100.357 139.186C100.178 142.756 97.5761 147.243 94.4598 149.357C91.8884 151.102 89.337 151.745 85.7709 151.546C81.4077 151.303 78.5816 149.766 75.9079 146.182Z"
                      fill="#3B82F6"/>
                <line id="Line 614" x1="162.263" y1="29.736" x2="11.236" y2="164.737" stroke="#64686C" stroke-width="6"
                      stroke-linecap="round"/>
            </g>
        </svg>
    )
}

export default BellIcon