const SettingsIcon = (props) => {
    return(
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="configuration" clip-path="url(#clip0_1216_19095)">
                <path id="Vector"
                      d="M7.23824 3.33345L9.41074 1.16095C9.56701 1.00472 9.77893 0.916962 9.9999 0.916962C10.2209 0.916962 10.4328 1.00472 10.5891 1.16095L12.7616 3.33345H15.8332C16.0542 3.33345 16.2662 3.42125 16.4225 3.57753C16.5788 3.73381 16.6666 3.94577 16.6666 4.16678V7.23845L18.8391 9.41095C18.9953 9.56722 19.0831 9.77915 19.0831 10.0001C19.0831 10.2211 18.9953 10.433 18.8391 10.5893L16.6666 12.7618V15.8334C16.6666 16.0545 16.5788 16.2664 16.4225 16.4227C16.2662 16.579 16.0542 16.6668 15.8332 16.6668H12.7616L10.5891 18.8393C10.4328 18.9955 10.2209 19.0833 9.9999 19.0833C9.77893 19.0833 9.56701 18.9955 9.41074 18.8393L7.23824 16.6668H4.16657C3.94556 16.6668 3.73359 16.579 3.57731 16.4227C3.42103 16.2664 3.33324 16.0545 3.33324 15.8334V12.7618L1.16074 10.5893C1.00451 10.433 0.916748 10.2211 0.916748 10.0001C0.916748 9.77915 1.00451 9.56722 1.16074 9.41095L3.33324 7.23845V4.16678C3.33324 3.94577 3.42103 3.73381 3.57731 3.57753C3.73359 3.42125 3.94556 3.33345 4.16657 3.33345H7.23824ZM4.9999 5.00012V7.92928L2.92907 10.0001L4.9999 12.0709V15.0001H7.92907L9.9999 17.0709L12.0707 15.0001H14.9999V12.0709L17.0707 10.0001L14.9999 7.92928V5.00012H12.0707L9.9999 2.92928L7.92907 5.00012H4.9999ZM9.9999 13.3334C9.11585 13.3334 8.268 12.9823 7.64288 12.3571C7.01776 11.732 6.66657 10.8842 6.66657 10.0001C6.66657 9.11606 7.01776 8.26821 7.64288 7.64309C8.268 7.01797 9.11585 6.66678 9.9999 6.66678C10.884 6.66678 11.7318 7.01797 12.3569 7.64309C12.982 8.26821 13.3332 9.11606 13.3332 10.0001C13.3332 10.8842 12.982 11.732 12.3569 12.3571C11.7318 12.9823 10.884 13.3334 9.9999 13.3334ZM9.9999 11.6668C10.4419 11.6668 10.8659 11.4912 11.1784 11.1786C11.491 10.8661 11.6666 10.4421 11.6666 10.0001C11.6666 9.55809 11.491 9.13416 11.1784 8.8216C10.8659 8.50904 10.4419 8.33345 9.9999 8.33345C9.55787 8.33345 9.13395 8.50904 8.82139 8.8216C8.50883 9.13416 8.33324 9.55809 8.33324 10.0001C8.33324 10.4421 8.50883 10.8661 8.82139 11.1786C9.13395 11.4912 9.55787 11.6668 9.9999 11.6668Z"
                      fill="#7FB8F0"/>
            </g>
            <defs>
                <clipPath id="clip0_1216_19095">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}
export default SettingsIcon