const CrossIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill={props?.color || "#FFF"}
             className="cross-Icon"
             onClick={props?.toggleDrawer ? props.toggleDrawer(false) : props?.handleCloseDialog? props.handleCloseDialog : props.clearData}>
            <path
                d="M7.99997 6.88906L11.889 3L13 4.11094L9.11091 8L13 11.8891L11.889 13L7.99997 9.11094L4.11091 13L2.99997 11.8891L6.88903 8L2.99997 4.11094L4.11091 3L7.99997 6.88906Z"
                fill="#E7E7E7"/>
        </svg>
    )
}

export default CrossIcon