import React, { useState, useEffect } from "react";
import { TableBody, TableCell, TableHead, TableRow, Table } from "@mui/material";
import { GeoDistributionConfig } from "../../../Configs/FormConfig/GeoDistribution";
import PropTypes from "prop-types";
import SimpleTelInput from "../../../Common/SimpleTelInput";

const GeoDistribution = (props) => {
    const [geoInventoryData, setGeoInventoryData] = useState([]);

    useEffect(() => {
        if (props.initialData?.length > 0) {
            setGeoInventoryData(props.initialData);
        }
    }, [props.initialData]);

    const onClickChange = (value, index, type, key) => {
        const updatedData = [...geoInventoryData];
        const itemIndex = updatedData.findIndex(item => item.region_key === key);

        if (itemIndex >= 0) {
            updatedData[itemIndex] = { region_key: key, region_name: key, percentage: value };
        } else {
            updatedData.push({ region_key: key, region_name: key, percentage: value });
        }

        setGeoInventoryData(updatedData);
        props.onChange(updatedData, props.formKey);
    };

    return (
        <div className="Device-dist-inner-div">
            <Table className="off-white-table ads-supply-table" stickyHeader>
                <TableHead>
                    <TableRow>
                        <TableCell>Regions</TableCell>
                        <TableCell>Percentage(%)</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {GeoDistributionConfig?.map((row, rowIndex) => {
                        const currentValue = geoInventoryData.find(item => item.region_key === row.fields[0].key)?.percentage || "";
                        return (
                            <TableRow key={rowIndex}>
                                <TableCell>{row.rowLabel}</TableCell>
                                <TableCell>
                                    <SimpleTelInput
                                        key={`${rowIndex}-${row.fields[0].key}`}
                                        type={row.fields[0].type}
                                        onChange={(value) => onClickChange(value, rowIndex, row.fields[0].type, row.fields[0].key)}
                                        textWidth={row.fields[0].width}
                                        placeholder={row.fields[0].placeholder}
                                        formKey={row.fields[0].key}
                                        value={currentValue}
                                    />
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </div>
    );
};

GeoDistribution.propTypes = {
    formKey: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    initialData: PropTypes.array, // Pass initial data
};

export default GeoDistribution;
