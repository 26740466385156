import axios from "axios";
import { BFF_LAYER_URI } from "../Constant";
import {adsHeader} from "../utils/helper";

export const getAlertDetailById = async (alert_group_id, alert_id, account_id, user_id) => {
    let data = JSON.stringify({
        query: `query MyQuery {
                      getAlertDetailById(
                        where: {alert_group_id: "${alert_group_id}", alert_id: "${alert_id}", account_id: ${account_id}, user_id: "${user_id}"}
                      ) {
                        status
                        message
                        data {
                          account_id
                          alert_group_id
                          alert_id
                          alert_level
                          alert_logging_info {
                            jira
                            slack
                          }
                          alert_rule_id
                          alert_rule_name
                          alert_timestamp
                          alert_type
                          channel_id
                          channel_name
                          created_at
                          deviation_type
                          entity_id
                          entity_name
                          entity_type
                          frequency
                          is_read
                          metric_name
                          metrics {
                            deviation
                            metric_name
                            previous_value
                            triggered_value
                          }
                          partner_id
                          recurrence
                          severity
                          threshold
                          updated_at
                        }
                      }
                    }`
    });
    const headers = adsHeader()

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: BFF_LAYER_URI,
        headers: headers,
        data: data
    };

    return axios.request(config)
        .then((response) => {
            if (response?.data?.data?.getAlertDetailById && response?.data?.data?.getAlertDetailById?.status === 200) {
                return response.data.data.getAlertDetailById;
            } else {
                return [];
            }
        })
        .catch((error) => {
            console.error('Request failed:', error);
            return null;
        });
};
