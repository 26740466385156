import React, {useEffect, useState} from 'react';
import './index.css';
import {Button, Chip, Grid, Skeleton, Typography} from "@mui/material";
import AddIcon from "../../../assests/icons/addIcon";
import DynamicTable from "../../../Components/Tables/DynamicTable";
import {usersTableConfig} from "../../../Configs/UserManagementConfig/UsersTable";
import DynamicTableTopFilter from "../../../Components/TableTopFilters/DynamicTableTopFilter";
import {usersTableContent} from "../../../Configs/UserManagementConfig/MockData";
import {DynamicSidebarForm} from "../../../Components/DynamicSidebarForm";
import Drawer from "@mui/material/Drawer";
import {AddUsersFormConfig} from "../../../Configs/UserManagementConfig/AddUsersFormConfig";
import BellIcon from "../../../assests/icons/BellIcon";
import {getNameFromEmail, isEmpty} from "../../../utils/helper";
import Notification from "../../../Components/Notification";
import CustomPagination from "../../../Common/CustomPagination";
import CustomDialogBox from "../../../Common/CustomDialogBox";
import {DialogDataConfig} from "../../../Configs/UserManagementConfig/DialogDataConfig";
import {getAmagiFridayUsers} from "../../../api/getAmagiFridayUsers";
import {
    ALL_DATA_FETCHED,
    DATA_IN_LOADING_STATE,
    ERROR_NOTIFICATION_TYPE,
    GETTING_THE_DATA,
    NO_DATA_FOUND_MESSAGE, SUCCESS_TYPE, USER_DATA_KEY, VALID_AMAGI_EMAIL_REGEX
} from "../../../Constant";
import {inviteAmagiFridayUsers} from "../../../api/inviteAmagiFridayUser";
import {updateAmagiFridayUser} from "../../../api/updateAmagiFridayUser";
import {removeAmagiFridayUser} from "../../../api/removeAmagiFridayUser";
import sessionStorageHelper from "../../../utils/sessionStorageHelper";
import {getLocalStorageData} from "../../../utils/localStorageHelper";


const UserManagementDashboard = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [alertType, setAlertType] = useState('');
    const [tableDataLoading, setTableDataLoading] = useState(true);
    const [loading, setLoading] = useState(true);
    const [chipFilterData, setChipFilterData] = useState([]);
    const [searchedData, setSearchedData] = useState([]);
    const [fullData, setFullData] = useState([]);
    const [isAddUsersSidebarOpen, setIsAddUsersSidebarOpen] = useState(false);
    const [pageSize, setPageSize] = useState(50);
    const [page, setPage] = useState(1);
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [totalUsers, setTotalUsers] = useState();
    const [isEditCall, setIsEditCall] = useState(false);
    const [userToDelete, setUserToDelete] = useState({});
    const [formValues, setFormValues] = useState(() => {
        const initialState = {};
        AddUsersFormConfig().forEach((field) => {
            if (field.key) {
                if (field.defaultValue !== undefined) {
                    initialState[field.key] = field.defaultValue;
                } else {
                    initialState[field.key] = field.type === "multi_select_with_search" ? [] : "";
                }
            }
        });
        return initialState;
    });
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [dialogData, setDialogData] = useState({});
    const [selectedChips, setSelectedChips] = useState([]);
    const [searchText, setSearchText] = useState("");
    const userData = getLocalStorageData(USER_DATA_KEY,true);
    const loadData = async () => {
        setTableDataLoading(true);
        try{
            const data = await getAmagiFridayUsers(((page-1) * pageSize), pageSize);
            const users = data?.data;
            const total_count = data?.total_count;

            if(users) {
                setSearchedData(users);
                setFullData(users);
                setTotalUsers(total_count);
            }
            else {
                setIsOpen(true);
                setNotificationMessage(NO_DATA_FOUND_MESSAGE);
                setAlertType(ERROR_NOTIFICATION_TYPE);
            }
        }
        catch (error) {
            setIsOpen(true);
            setTotalUsers(0);
            setNotificationMessage(DATA_IN_LOADING_STATE);
            setAlertType(ERROR_NOTIFICATION_TYPE);
        }
        finally {
            setSearchText("");
            setTableDataLoading(false);
        }
    }
    useEffect(() => {
        setSelectedChips([]);
        loadData()
            .then(() => {
                setIsOpen(true);
                setNotificationMessage(ALL_DATA_FETCHED);
                setAlertType(SUCCESS_TYPE);
            })
            .catch(() => {
                setIsOpen(true);
                setNotificationMessage(DATA_IN_LOADING_STATE);
                setAlertType(ERROR_NOTIFICATION_TYPE);
            });
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, [page, pageSize]);
    useEffect(() => {
        const chipData = [
            { label: "Admin", count: (fullData.filter((item) => item.user_roles.includes("Admin")).length || 0)},
            { label: "Adops", count: (fullData.filter((item) => item.user_roles.includes("Adops")).length || 0)},
            { label: "AdsPlus", count: (fullData.filter((item) => item.user_roles.includes("AdsPlus")).length || 0)},
            { label: "Signup pending", count: (fullData.filter((item) => item.user_status === "pending").length || 0)},
        ];
        setChipFilterData(chipData);
    }, [searchedData]);
    useEffect(() => {
        setPage(1);
    }, [pageSize]);
    useEffect(() => {
        if(!isEmpty(formValues.user_name) && !isEmpty(formValues.full_name) && !isEmpty(formValues.user_roles) && formValues.user_name.endsWith("@amagi.com") && new RegExp(VALID_AMAGI_EMAIL_REGEX).test(formValues.user_name)) {
            setAllowSubmit(true);
        }
        else {
            setAllowSubmit(false);
        }
    }, [formValues]);
    const handleNotificationClose = () => {
        setIsOpen(false);
    };
    const toggleDrawer = (state) => () => {
        if(!state){
            setIsEditCall(false);
            clearForm();
        }
        setIsAddUsersSidebarOpen(state);
    }
    const toggleDialog = (state) => {
        if(!state) {
            setAllowSubmit(false);
        }
        setIsDialogOpen(state);
    }
    const handleInputChange = (key, value, isMultiSelect = false) => {
        setFormValues((prev) => {
            if (isMultiSelect) {
                const currentValues = prev[key] || [];

                if (value === "selectAll") {
                    return {
                        ...prev,
                        [key]: [],
                    };
                }
                if (value === "clearAll") {
                    return {
                        ...prev,
                        [key]: [],
                    };
                }
                if (currentValues.includes(value)) {
                    // Remove the value if it exists
                    return {
                        ...prev,
                        [key]: currentValues.filter((item) => item !== value),
                    };
                } else {
                    // Add the value if it doesn't exist
                    return {
                        ...prev,
                        [key]: [...currentValues, value],
                    };
                }
            } else {
                return {
                    ...prev,
                    [key]: value,
                };
            }
        });
    };
    const handlePageChange = (newPage, newSize) => {
        setPage(newPage);
        setPageSize(newSize);
    };
    const handlePageSizeChange = (newSize) => {
        setPageSize(newSize);
    };
    const handleSort = (columnKey, sortOrder) => {
        const sortedData = [...searchedData].sort((firstItem, secondItem) => {
            const firstValue = firstItem[columnKey];
            const secondValue = secondItem[columnKey];

            if (sortOrder === "ascending") {
                return firstValue > secondValue ? 1 : -1;
            } else {
                return firstValue < secondValue ? 1 : -1;
            }
        });
        setSearchedData(sortedData);
    };
    const clearForm = () => {
        setIsEditCall(false);
        setFormValues(() => {
            const initialState = {};
            AddUsersFormConfig().forEach((field) => {
                if (field.key) {
                    if (field.defaultValue !== undefined) {
                        initialState[field.key] = field.defaultValue;
                    } else {
                        initialState[field.key] = field.type === "multi_select_with_search" ? [] : "";
                    }
                }
            });
            return initialState;
        });
    }
    const onSearchFilter = (text = "") => {
        setSearchText(text);
        if (isEmpty(text)) {
            setSearchedData(fullData);
            return;
        }
        const contentData = text
            ? fullData.filter((item) => ( item.full_name.toLowerCase().includes(text.toLowerCase()) || item.user_name.toLowerCase().includes(text.toLowerCase())) )
            : fullData;
        setSearchedData(contentData);
    };
    const handleActions = async (action, data) => {
        if(action === "Resend invite") {
            let values = {
                user_name: data.user_name,
                full_name: data.full_name,
                user_roles: data.user_roles
            }
            const resendInviteResponse = await inviteAmagiFridayUsers(values, true);
            if(!isEmpty(resendInviteResponse?.user_name)) {
                setIsOpen(true);
                setNotificationMessage(resendInviteResponse?.message || "Successfully sent invite");
                setAlertType('success');
            }
            else {
                setIsOpen(true);
                setNotificationMessage(resendInviteResponse?.createAmagiFridayUser?.data?.createAmagiFridayUser?.message || "Failed to resend invite");
                setAlertType('error');
            }
        }
        if(action === "Delete user") {
            setAllowSubmit(true);
            setDialogData(DialogDataConfig("delete user"));
            setUserToDelete(data);
            toggleDialog(true);
        }
        if(action === "Change role") {
            setIsEditCall(true);
            setFormValues((prev) => {
                const {user_name, full_name, user_roles} = data;
                return {
                    ...prev,
                    user_name,
                    full_name,
                    user_roles
                }
            });
            toggleDrawer(true)();
        }
    }
    const handleSubmit = async () => {
        setAllowSubmit(false);
        if(isEditCall) {
            const response = await updateAmagiFridayUser({ user_name: formValues.user_name, user_roles: formValues.user_roles });
            if(!isEmpty(response?.updateAmagiFridayUser?.user_name)) {
                setIsOpen(true);
                setNotificationMessage(response.updateAmagiFridayUser.message);
                setAlertType('success');
                await loadData();
            }
            else{
                setIsOpen(true);
                setNotificationMessage(response?.updateAmagiFridayUser?.message || "Failed to update user role");
                setAlertType('error');
            }
            setIsEditCall(false);
        }
        else {
            const response = await inviteAmagiFridayUsers(formValues);
            if(!isEmpty(response?.createAmagiFridayUser?.user_name || null)) {
                setIsOpen(true);
                setNotificationMessage(response?.createAmagiFridayUser?.message);
                setAlertType('success');
                await loadData();
            }
            else{
                setIsOpen(true);
                setNotificationMessage(response?.createAmagiFridayUser?.data?.createAmagiFridayUser?.message || "Something went wrong");
                setAlertType('error');
            }
        }
        toggleDrawer(false)();
        clearForm();
    }
    const handleDialogAction = async() => {
        if(dialogData.actionType === "delete") {
            setAllowSubmit(false);
            const response = await removeAmagiFridayUser({ user_name: userToDelete.user_name });
            if(response) {
                setIsOpen(true);
                setNotificationMessage("User removed successfully");
                setAlertType('success');
                setUserToDelete({});
                loadData();
            }
            else {
                setIsOpen(true);
                setNotificationMessage("Failed to remove user");
                setAlertType('error');
            }
            toggleDialog(false);
        }
    }
    const handleChipClick = async (label) => {
        if(selectedChips.includes(label)) {
            setSelectedChips(selectedChips.filter((item) => item !== label));
        }
        else {
            setSelectedChips([...selectedChips, label]);
        }
    };
    useEffect(() => {
        if (selectedChips.length === 0) {
            setSearchedData(fullData);
            return;
        }
        const filteredData = fullData.filter((item) => {
            return selectedChips.some((chip) => {
                if (chip === "Admin") {
                    return item.user_roles.includes("Admin");
                }
                if (chip === "Adops") {
                    return item.user_roles.includes("Adops");
                }
                if (chip === "AdsPlus") {
                    return item.user_roles.includes("AdsPlus");
                }
                if (chip === "Signup pending") {
                    return item.user_status === "pending";
                }
                return false; // Default to false if the chip doesn't match any criteria
            });
        });

        setSearchedData(filteredData);
    }, [selectedChips, fullData]);

    return (
        <React.Fragment>
            <CustomDialogBox
                isDialogOpen={isDialogOpen}
                handleCloseDialog={toggleDialog}
                dialogData={dialogData}
                handleAction={() => handleDialogAction()}
                isApproving={false}
                allowSubmit = {allowSubmit}
            />
            <Drawer
                anchor="right"
                open={isAddUsersSidebarOpen}
                onClose={toggleDrawer(false)}
                className="create-rules-sidebar"
            >
                <DynamicSidebarForm
                    handleCloseForm={toggleDrawer(false)}
                    formConfig={AddUsersFormConfig()}
                    formValues={formValues}
                    handleInputChange={handleInputChange}
                    handleSubmit={handleSubmit}
                    isEditCall={isEditCall}
                    allowSubmit={allowSubmit}
                />
            </Drawer>
            <Grid container rowSpacing={2} >
                <Grid item xs={12} className="screen-header">
                    <Typography>User Management</Typography>
                    <Button className="orange-button-with-icon orange-button" onClick={toggleDrawer(true)}>
                        <AddIcon color={"#0f0f0f"}/> Add user
                    </Button>
                </Grid>

                {loading ? (
                    <Grid item xs={12}>
                        <Skeleton variant="text" width={550} height={60} className="alerts-skeleton-loading" />
                        <Skeleton variant="rectangular" width="100%" height={600} className="alerts-skeleton-loading" />
                    </Grid>
                ) : totalUsers === 0 ? (
                    <Grid item xs={12}>
                        <div className="empty-alerts-content">
                            <BellIcon />
                            <div className="es-heading">No users</div>
                            <div className="es-sub-heading">Click 'Add Users' to invite Amagi Friday UI users.</div>
                        </div>
                    </Grid>
                ) : (
                    <React.Fragment>
                        <Grid item xs={12} className="screen-chip-filter">
                            {chipFilterData.map((item, index) => (
                                <Chip
                                    key={index}
                                    disabled={item.count === 0}
                                    label={`${item.label}: ${item.count}`}
                                    onClick={()=> handleChipClick(item.label)}
                                    variant="outlined"
                                    className={selectedChips.includes(item.label) ? "screen-chips-selected" : "screen-chips"}
                                />
                            ))}
                        </Grid>
                        <Grid item xs={12} >
                            <Grid item xs={12} className="amg-filter-content">
                                <DynamicTableTopFilter
                                    count={totalUsers}
                                    titleText={ totalUsers > 1 ? "Users" : "User" }
                                    onSearch={onSearchFilter}
                                    searchText={searchText}
                                    showFilter={false}
                                />
                            </Grid>
                            <Grid container className="dynamic-table-content">
                                <DynamicTable
                                    tableContent={searchedData}
                                    isLoading={tableDataLoading}
                                    // searchedText={searchText}
                                    tableConfig={usersTableConfig}
                                    highlightUnseen={false}
                                    onSort={handleSort}
                                    onClickAction={handleActions}
                                    emptyStateMessage={"No users found!"}
                                    userData = {userData}
                                />
                            </Grid>
                            <Grid item xs={12} >
                                <div className="amg-pagination-section" >
                                    <CustomPagination
                                        totalItems={totalUsers}
                                        itemsPerPage={pageSize}
                                        onPageChange={handlePageChange}
                                        onPageSizeChange={handlePageSizeChange}
                                        apiDriven={false}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </React.Fragment>
                )}
            </Grid>
            <Notification
                open={isOpen}
                message={notificationMessage}
                handleClose={handleNotificationClose}
                type={alertType}
            />
        </React.Fragment>
    )
}


export default UserManagementDashboard;
