import axios from "axios";

// Define constant for report download endpoint
import { REPORT_DOWNLOAD_URI } from "../Constant";

export const downloadReportFile = async (fileName, file_url) => {
    try {
        // Make GET request to download the report
        const response = await axios.get(`${REPORT_DOWNLOAD_URI}/download?file_name=${file_url}`, {
        });

        // Create a blob object from the response data
        const blob = new Blob([response.data], { type: 'text/csv' });

        // Create a temporary URL for the blob object
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        // Simulate click on the link to initiate download
        document.body.appendChild(link);
        link.click();

        // Cleanup: remove the temporary link and URL object
        link.parentNode.removeChild(link);
        window.URL.revokeObjectURL(url);

        return true;
    } catch (error) {
        console.error("Error downloading report:", error);
        return false;
    }
};
