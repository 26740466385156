 export const jobDetails ={
    "data": {
    "mb_job_status": {
        "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "Action": "Bulk Append",
            "EntityType": "TsTagBulk",
            "Description": "Bulk Append [app_name] macro(s)",
            "PlatformID": "AMG00346",
            "ChannelIDs": "amg00346-vizioinc-action-vizious,amg00346-vizioinc-angermgmtvizio-vizious,amg00353-lionsgatestudio-arewethereyet-vizious,amg00312-graytelevisioni-arizonasfamily-vizious,amg00312-graytelevisioni-atlantanewsfirst-vizious,996c2881-f6f1-286d-a23c-117efe9a6984,vizioAAAA-aweencore-vizio,amg00145-buzzr-bigballs-baywatch-vizious,amg00346-vizioinc-blackbrilliance-vizious,vizioAAAA-blacknewsnetwork-vizio,amg01201-cinedigmenterta-bloodydisgusting-vizious,condenast-bonappetit-vizio,amg00346-vizioinc-brightlinetesthome-vizious,vizioAAAA-buzzr-ott-vizio,vizioAAAA-carol-burnett-vizio,amg01420-americastestkitchen-americastestkitchen-vizious,amg01918-cnnus-cnnheadlines-vizious,amg00353-lionsgatetvfast-ebonytvvizio-vizious,amg00346-vizioincfast-forkandflight-distrotvus,amg00346-vizioincfast-forkandflight-plexus",
            "UserID": "abhijeet.bharti@amagi.com",
            "Status": "Success",
            "Summary": "Record(s) status summary, succeeded: 55, failed: 0",
            "Meta": {
            "job_status": {
                "total": 55,
                    "failure": {
                    "not_found_in_db_count": 0,
                        "error_processing_count": 0,
                        "failure_valid_tag_count": 0
                },
                "success": {
                    "skipped_count": 21,
                        "success_valid_tag_count": 34
                }
            }
        },
        "CreatedAt": "2024-03-23T16:19:54.060645Z"
    },
    "mb_job_details": [
        {
            "EntityType": "DemandTag",
            "EntityID": 1273382,
            "EntityName": "BlackNews Channel - SS - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "vizioAAAA-blacknewsnetwork-vizio",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://vid.springserve.com/rt/1249?content_genre=news\&did={{DEVICE_ID}}\&demand_custom_app_bundle={{CUSTOM_APP_BUNDLE}}\&ip={{IP}}\&ua={{USER_AGENT}}\&demand_custom_cb={{CUSTOM_CACHEBUSTER}}\&app_name={{APP_NAME}}\&h=1080\&vizdartoken={{QP_vizdartoken}}\&app_bundle=vizio.watchfree\&schain={{SCHAIN}}\&w=1920\&rating=TV-PG\&us_privacy={{US_PRIVACY}}\&cb={{CACHEBUSTER}}\&content_id={{CONTENT_ID}}\&lmt=0\&appId=tzx5azr8\&pod_max_dur={{POD_MAX_DUR}}\&app_store_url=https://www.vizio.com/en/smart-tv-apps?appName=watchfree\"}"
            },
            "CreatedAt": "2024-03-23T16:19:45.271332Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273152,
            "EntityName": "Action - Ale Atiads - Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioinc-action-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:45.304088Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273204,
            "EntityName": "Ebony TV Vizio - Ale Atiads - Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00353-lionsgatetvfast-ebonytvvizio-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:45.338207Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273276,
            "EntityName": "Ads Plus - Ads+ - Bloody Disgusting - Vizio_PFE2",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg01201-cinedigmenterta-bloodydisgusting-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:45.369894Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45629,
            "EntityName": "amg00346-vizioinc-angermgmtvizio-vizious-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioinc-angermgmtvizio-vizious",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:47.094929Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45449,
            "EntityName": "996c2881-f6f1-286d-a23c-117efe9a6984",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "996c2881-f6f1-286d-a23c-117efe9a6984",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:47.479194Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45684,
            "EntityName": "vizioAAAA-aweencore-vizio-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "vizioAAAA-aweencore-vizio",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:47.881821Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45631,
            "EntityName": "amg00346-vizioinc-brightlinetesthome-vizious-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioinc-brightlinetesthome-vizious",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:48.248711Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45689,
            "EntityName": "vizioAAAA-carol-burnett-vizio-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "vizioAAAA-carol-burnett-vizio",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:48.666063Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45643,
            "EntityName": "amg00346-vizioincfast-forkandflight-distrotvus-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioincfast-forkandflight-distrotvus",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:49.041086Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45628,
            "EntityName": "amg00346-vizioinc-action-vizious-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioinc-action-vizious",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:49.430407Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45608,
            "EntityName": "amg00312-graytelevisioni-arizonasfamily-vizious-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00312-graytelevisioni-arizonasfamily-vizious",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:49.786441Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45602,
            "EntityName": "amg00145-buzzr-bigballs-baywatch-vizious-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00145-buzzr-bigballs-baywatch-vizious",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:50.171962Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273123,
            "EntityName": "Atlanta_News_First - Vizio - Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00312-graytelevisioni-atlantanewsfirst-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.ads.vizio.com/rt/17834?app_bundle=vizio.watchfree\&app_name={{APP_NAME}}\&us_privacy={{US_PRIVACY}}\&app_store_url=https://www.vizio.com/en/smart-tv-apps?appName=watchfree\&appId=vizio.watchfree\&cb={{CACHEBUSTER}}\&ip={{IP}}\&did={{DEVICE_ID}}\&lmt={{LMT}}\&ifa_type={{IFA_TYPE}}\&h=1080\&pod_max_dur={{POD_MAX_DUR}}\&rating=tv-pg\&appid=pl1un323\&vizdartoken={{QP_vizdartoken}}\&w=1920\&ua={{USER_AGENT}}\&content_genre=news\&content_id={{CONTENT_ID}}\"}"
            },
            "CreatedAt": "2024-03-23T16:19:40.234423Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273153,
            "EntityName": "Action Backfill - Ale Atiads - Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioinc-action-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:40.318336Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273277,
            "EntityName": "Ads Plus - Ads+ - Bloody Disgusting - Vizio_SFE3",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg01201-cinedigmenterta-bloodydisgusting-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:40.351096Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273306,
            "EntityName": "CNN Headlines - Ale Atiads Backfill - Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg01918-cnnus-cnnheadlines-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:40.383917Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273386,
            "EntityName": "Buzzr - Ale Backfill - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "vizioAAAA-buzzr-ott-vizio",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:40.415163Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1272498,
            "EntityName": "934ed2e6-442d-81a5-4a8d-40dd30c5cbc3",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "996c2881-f6f1-286d-a23c-117efe9a6984",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:40.448401Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273156,
            "EntityName": "BlackBrilliance - Ale Atiads - Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioinc-blackbrilliance-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:40.478845Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273157,
            "EntityName": "BlackBrilliance Backfill - Ale Atiads - Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioinc-blackbrilliance-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:40.512588Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273180,
            "EntityName": "Fork and Flight - Vizio-CTV - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioincfast-forkandflight-distrotvus",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.ads.vizio.com/rt/33059?w=1920\&h=1080\&app_bundle=vizio.distrotv\&app_name={{APP_NAME}}\&ifa_type={{IFA_TYPE}}\&pod_max_dur={{POD_MAX_DUR}}\&channel_name={{CHANNEL_NAME}}\&appId=vizio.distrotv\&vizdartoken={{QP_vizdartoken}}\&ip={{IP}}\&content_genre=food\&rating={{QP_content_rating}}\&app_store_url=https://www.vizio.com/en/smart-tv-apps?appName=distrotv\&cb={{CACHEBUSTER}}\&ua={{USER_AGENT}}\&did={{DEVICE_ID}}\&us_privacy={{US_PRIVACY}}\&lmt={{DNT}}\"}"
            },
            "CreatedAt": "2024-03-23T16:19:40.756285Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273305,
            "EntityName": "CNN Headlines - Ale Atiads - Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg01918-cnnus-cnnheadlines-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:41.300982Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273183,
            "EntityName": "Fork and Flight - Vizio Desktop - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioincfast-forkandflight-distrotvus",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.ads.vizio.com/rt/33056?ua={{USER_AGENT}}\&did={{DEVICE_ID}}\&channel_name={{CHANNEL_NAME}}\&lmt={{DNT}}\&ifa_type={{IFA_TYPE}}\&w=1920\&app_name={{APP_NAME}}\&content_genre=food\&cb={{CACHEBUSTER}}\&us_privacy={{US_PRIVACY}}\&vizdartoken={{QP_vizdartoken}}\&h=1080\&app_bundle={{APP_BUNDLE}}\&rating={{QP_content_rating}}\&ip={{IP}}\"}"
            },
            "CreatedAt": "2024-03-23T16:19:41.332907Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273155,
            "EntityName": "AngerMgmtVizio - Ale Atiads - Back fill Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioinc-angermgmtvizio-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:41.792942Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45630,
            "EntityName": "amg00346-vizioinc-blackbrilliance-vizious-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioinc-blackbrilliance-vizious",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:50.55587Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45688,
            "EntityName": "vizioAAAA-buzzr-ott-vizio-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "vizioAAAA-buzzr-ott-vizio",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:50.92971Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45650,
            "EntityName": "amg00353-lionsgatestudio-arewethereyet-vizious-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00353-lionsgatestudio-arewethereyet-vizious",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:51.316182Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45685,
            "EntityName": "vizioAAAA-blacknewsnetwork-vizio-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "vizioAAAA-blacknewsnetwork-vizio",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:51.701097Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45564,
            "EntityName": "Bon Appetit - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "condenast-bonappetit-vizio",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:52.086597Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273122,
            "EntityName": "Arizonas_Family - Vizio  - Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00312-graytelevisioni-arizonasfamily-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.ads.vizio.com/rt/17835?h=1080\&pod_max_dur={{POD_MAX_DUR}}\&app_bundle=vizio.watchfree\&rating=tv-pg\&lmt={{LMT}}\&vizdartoken={{QP_vizdartoken}}\&cb={{CACHEBUSTER}}\&ip={{IP}}\&did={{DEVICE_ID}}\&us_privacy={{US_PRIVACY}}\&ifa_type={{IFA_TYPE}}\&ua={{USER_AGENT}}\&content_genre=news\&app_store_url=https://www.vizio.com/en/smart-tv-apps?appName=watchfree\&appId=vizio.watchfree\&content_id={{CONTENT_ID}}\&w=1920\&app_name={{APP_NAME}}\&appid=pl1un324\"}"
            },
            "CreatedAt": "2024-03-23T16:19:41.827817Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273158,
            "EntityName": "BrightlineTestHome - Vizio  - Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioinc-brightlinetesthome-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.ads.vizio.com/rt/15979?did={{DEVICE_ID}}\&ua={{USER_AGENT}}\&app_name={{APP_NAME}}\&channel_name={{CHANNEL_NAME}}\&w=1920\&h=1080\&app_bundle=vizio.watchfree\&rating={{RATING}}\&lmt={{LMT}}\&vizdartoken={{QP_vizdartoken}}\&cb={{CACHEBUSTER}}\&ip={{IP}}\&pod_max_dur={{POD_MAX_DUR}}\&us_privacy={{US_PRIVACY}}\&content_genre=tvmovies\&ifa_type={{IFA_TYPE}}\&app_store_url=https://www.vizio.com/en/smart-tv-apps?appName=watchfree\&appId=vizio.watchfree\"}"
            },
            "CreatedAt": "2024-03-23T16:19:42.202677Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45673,
            "EntityName": "amg01420-americastestkitchen-americastestkitchen-vizious-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg01420-americastestkitchen-americastestkitchen-vizious",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:52.479628Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45609,
            "EntityName": "amg00312-graytelevisioni-atlantanewsfirst-vizious-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00312-graytelevisioni-atlantanewsfirst-vizious",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:52.816224Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45557,
            "EntityName": "Bloody Disgusting - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg01201-cinedigmenterta-bloodydisgusting-vizious",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:53.206192Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45678,
            "EntityName": "amg01918-cnnus-cnnheadlines-vizious-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg01918-cnnus-cnnheadlines-vizious",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:53.574222Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273187,
            "EntityName": "Fork and Flight - Vizio CTV - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioincfast-forkandflight-plexus",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.ads.vizio.com/rt/30550?content_episode={{CONTENT_EPISODE}}\&content_producer_name={{QP_studio_name}}\&content_series={{CONTENT_SERIES}}\&vizdartoken={{QP_vizdartoken}}\&pod_max_dur={{POD_MAX_DUR}}\&lmt={{DNT}}\&ifa_type={{IFA_TYPE}}\&app_bundle={{APP_BUNDLE}}\&app_name={{APP_NAME}}\&did={{DEVICE_ID}}\&us_privacy={{US_PRIVACY}}\&app_store_url={{APP_STORE_URL}}\&w=1920\&cb={{CACHEBUSTER}}\&ip={{IP}}\&content_id={{CONTENT_ID}}\&h=1080\&rating={{QP_content_rating}}\&channel_name={{QP_studio_name}}\&content_season={{CONTENT_SEASON}}\&content_title={{CONTENT_TITLE}}\&ua={{USER_AGENT}}\&content_genre=food\&content_livestream={{CONTENT_LIVESTREAM}}\"}"
            },
            "CreatedAt": "2024-03-23T16:19:42.609285Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273203,
            "EntityName": "Are We ThereYet - Ale - Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00353-lionsgatestudio-arewethereyet-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:42.644422Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273279,
            "EntityName": "Bloody Disgusting - Ale Atiads - Vizio - 1L",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg01201-cinedigmenterta-bloodydisgusting-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:42.678569Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273385,
            "EntityName": "Buzzr - Ale - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "vizioAAAA-buzzr-ott-vizio",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:42.710189Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273154,
            "EntityName": "AngerMgmtVizio - Vizio US - Ale Atiads - Vizio US 1L",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioinc-angermgmtvizio-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:42.790282Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273182,
            "EntityName": "Fork and Flight - Vizio Mobile - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioincfast-forkandflight-distrotvus",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.ads.vizio.com/rt/33057?channel_name={{CHANNEL_NAME}}\&lmt={{DNT}}\&cb={{CACHEBUSTER}}\&us_privacy={{US_PRIVACY}}\&content_genre=food\&ifa_type={{IFA_TYPE}}\&w=1920\&did={{DEVICE_ID}}\&rating={{QP_content_rating}}\&vizdartoken={{QP_vizdartoken}}\&ip={{IP}}\&ua={{USER_AGENT}}\&app_bundle={{APP_BUNDLE}}\&app_name={{APP_NAME}}\&app_store_url={{APP_STORE_URL}}\&h=1080\"}"
            },
            "CreatedAt": "2024-03-23T16:19:43.078272Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273296,
            "EntityName": "Americas Test Kitchen - Ale Atiads - Vizio US",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg01420-americastestkitchen-americastestkitchen-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:43.541587Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273387,
            "EntityName": "Carol Burnett - ale - Vizio - 1L",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "vizioAAAA-carol-burnett-vizio",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:43.575982Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273186,
            "EntityName": "Fork and Flight - Desktop Vizio - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioincfast-forkandflight-plexus",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.ads.vizio.com/rt/30554?cb={{CACHEBUSTER}}\&ua={{USER_AGENT}}\&content_id={{CONTENT_ID}}\&content_series={{CONTENT_SERIES}}\&did={{DEVICE_ID}}\&us_privacy={{US_PRIVACY}}\&content_episode={{CONTENT_EPISODE}}\&content_livestream={{CONTENT_LIVESTREAM}}\&vizdartoken={{QP_vizdartoken}}\&ip={{IP}}\&app_bundle={{APP_BUNDLE}}\&app_name={{APP_NAME}}\&content_title={{CONTENT_TITLE}}\&w=1920\&h=1080\&content_genre=food\&rating={{QP_content_rating}}\&channel_name={{QP_studio_name}}\&lmt={{DNT}}\&ifa_type={{IFA_TYPE}}\&content_producer_name={{QP_studio_name}}\&content_season={{CONTENT_SEASON}}\"}"
            },
            "CreatedAt": "2024-03-23T16:19:43.60783Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273112,
            "EntityName": "Baywatch - Ale Atiads - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00145-buzzr-bigballs-baywatch-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:43.638354Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273181,
            "EntityName": "Fork and Flight - Vizio Off Platform-CTV - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioincfast-forkandflight-distrotvus",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.ads.vizio.com/rt/33058?w=1920\&h=1080\&rating={{QP_content_rating}}\&app_store_url={{APP_STORE_URL}}\&vizdartoken={{QP_vizdartoken}}\&channel_name={{CHANNEL_NAME}}\&lmt={{DNT}}\&cb={{CACHEBUSTER}}\&ua={{USER_AGENT}}\&pod_max_dur={{POD_MAX_DUR}}\&app_name={{APP_NAME}}\&content_genre=food\&ip={{IP}}\&app_bundle={{APP_BUNDLE}}\&did={{DEVICE_ID}}\&us_privacy={{US_PRIVACY}}\&ifa_type={{IFA_TYPE}}\"}"
            },
            "CreatedAt": "2024-03-23T16:19:44.012604Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273184,
            "EntityName": "Fork and Flight -  Vizio Off Platform-CTV - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioincfast-forkandflight-plexus",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.ads.vizio.com/rt/30551?cb={{CACHEBUSTER}}\&ua={{USER_AGENT}}\&pod_max_dur={{POD_MAX_DUR}}\&app_bundle={{APP_BUNDLE}}\&us_privacy={{US_PRIVACY}}\&channel_name={{QP_studio_name}}\&w=1920\&h=1080\&lmt={{DNT}}\&vizdartoken={{QP_vizdartoken}}\&ifa_type={{IFA_TYPE}}\&content_season={{CONTENT_SEASON}}\&did={{DEVICE_ID}}\&content_genre=food\&content_producer_name={{QP_studio_name}}\&content_title={{CONTENT_TITLE}}\&app_name={{APP_NAME}}\&content_episode={{CONTENT_EPISODE}}\&app_store_url={{APP_STORE_URL}}\&content_id={{CONTENT_ID}}\&content_livestream={{CONTENT_LIVESTREAM}}\&content_series={{CONTENT_SERIES}}\&ip={{IP}}\&rating={{QP_content_rating}}\"}"
            },
            "CreatedAt": "2024-03-23T16:19:44.410391Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273113,
            "EntityName": "Baywatch - AleAtiads - Backfill Vizio 1",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00145-buzzr-bigballs-baywatch-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:44.444859Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273185,
            "EntityName": "Fork and Flight - Vizio Mobile - Vizio",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioincfast-forkandflight-plexus",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.ads.vizio.com/rt/30553?w=1920\&ip={{IP}}\&content_episode={{CONTENT_EPISODE}}\&content_producer_name={{QP_studio_name}}\&vizdartoken={{QP_vizdartoken}}\&ifa_type={{IFA_TYPE}}\&app_store_url={{APP_STORE_URL}}\&content_series={{CONTENT_SERIES}}\&h=1080\&ua={{USER_AGENT}}\&app_name={{APP_NAME}}\&us_privacy={{US_PRIVACY}}\&lmt={{DNT}}\&content_title={{CONTENT_TITLE}}\&app_bundle={{APP_BUNDLE}}\&content_genre=food\&channel_name={{QP_studio_name}}\&content_season={{CONTENT_SEASON}}\&cb={{CACHEBUSTER}}\&did={{DEVICE_ID}}\&rating={{QP_content_rating}}\&content_id={{CONTENT_ID}}\&content_livestream={{CONTENT_LIVESTREAM}}\"}"
            },
            "CreatedAt": "2024-03-23T16:19:44.876964Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45651,
            "EntityName": "amg00353-lionsgatetvfast-ebonytvvizio-vizious-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00353-lionsgatetvfast-ebonytvvizio-vizious",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:54.028139Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273278,
            "EntityName": "Bloody Disgusting Vizio - SS - Cinedigm",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg01201-cinedigmenterta-bloodydisgusting-vizious",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.springserve.com/rt/9701?demand_custom_app_bundle={{CUSTOM_APP_BUNDLE}}\&did={{DEVICE_ID}}\&consent={{CONSENT}}\&device_model={{DEVICE_MODEL}}\&brand_name={{DEVICE_BRAND_NAME}}\&lmt={{LMT}}\&h=1080\&dnt={{LMT}}\&demand_custom_cb={{CUSTOM_CACHEBUSTER}}\&cb={{CACHEBUSTER}}\&w=1920\&gdpr={{GDPR}}\&coppa={{COPPA}}\&pod_max_dur={{POD_MAX_DUR}}\&app_bundle=vizio.watchfree.bloodydisgusting\&ip={{IP}}\&iris_id={{IRIS_ID}}\&device_make={{DEVICE_MAKE}}\&iris_context={{IRIS_CONTEXT}}\&ua={{USER_AGENT}}\&us_privacy={{US_PRIVACY}}\&app_name={{APP_NAME}}\&app_store_url={{APP_STORE_URL}}\"}"
            },
            "CreatedAt": "2024-03-23T16:19:45.714942Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273309,
            "EntityName": "Bon Appetit Vizio - SS - Conde Nast",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "condenast-bonappetit-vizio",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body":"{\"vast_endpoint_url\":\"https://tv.springserve.com/rt/6850?app_bundle=vizio.watchfree\&content_genre={{CONTENT_GENRE}}\&did={{DEVICE_ID}}\&lmt={{LMT}}\&h=1080\&app_store_url=https://www.vizio.com/en/smart-tv-apps?appName=watchfree\&appId=vizio.watchfree\&demand_custom_cb={{CUSTOM_CACHEBUSTER}}\&us_privacy={{US_PRIVACY}}\&w=1920\&brand={{DEVICE_BRAND_NAME}}\&ifa_type=vida\&ua={{USER_AGENT}}\&demand_custom_app_bundle={{CUSTOM_APP_BUNDLE}}\&app_name={{APP_NAME}}\&content_title={{CONTENT_TITLE}}\&vid={{QP_vid}}\&cb={{CACHEBUSTER}}\"}"
            },
            "CreatedAt": "2024-03-23T16:19:46.112127Z"
        },
        {
            "EntityType": "DemandTag",
            "EntityID": 1273310,
            "EntityName": "Bon Appetit - Ale Atiads - Vizio - 1L",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "condenast-bonappetit-vizio",
            "AdTagTSSync": "NA",
            "Success": true,
            "Reason": "macros already present, update skipped",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:46.142935Z"
        },
        {
            "EntityType": "SupplyRouter",
            "EntityID": 45644,
            "EntityName": "amg00346-vizioincfast-forkandflight-plexus-SR",
            "JobID": "1bc81279-e968-4a5d-b849-39ee5050608e",
            "PlatformID": "AMG00346",
            "ChannelID": "amg00346-vizioincfast-forkandflight-plexus",
            "AdTagTSSync": "failure",
            "Success": true,
            "Reason": "macros added successfully",
            "Meta": {
                "req_body": "{}"
            },
            "CreatedAt": "2024-03-23T16:19:46.655644Z"
        }
    ]
}
}