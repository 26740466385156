export const usersTableContent = [
    {
        name: "Vedant Gaidhane",
        email_id: "vedant.gaidhane@amagi.com",
        role: "Admin",
        added_on: 1695172000005,
        last_logged_in: 1695072000000,
        status: "pending",
    },
    {
        name: "Abhijeet Bharti",
        email_id: "abhijeet.bharti@amagi.com",
        role: "Admin",
        added_on: 1695272000006,
        last_logged_in: 1695072078000,
        status: "Active",
    },
    {
        name: "John Doe",
        email_id: "john.doe@amagi.com",
        role: "Adops",
        added_on: 1695372000007,
        last_logged_in: 1695072000260,
        status: "Active",
    },
    {
        name: "John Doe",
        email_id: "john.doe@amagi.com",
        role: "Adops",
        added_on: 1695472000008,
        last_logged_in: 1695072045000,
        status: "pending",
    },
    {
        name: "John Doe",
        email_id: "john.doe@amagi.com",
        role: "Business",
        added_on: 1695572000009,
        last_logged_in: 1695072000067,
        status: "Active",
    },
    {
        name: "John Doe",
        email_id: "john.doe@amagi.com",
        role: "Business",
        added_on: 1695672000000,
        last_logged_in: 1695072096000,
        status: "Active",
    },
    {
        name: "John Doe",
        email_id: "john.doe@amagi.com",
        role: "Admin",
        added_on: 1695772000000,
        last_logged_in: 1695072240000,
        status: "Active",
    },
];