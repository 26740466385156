export const alertsTableContent = [
    {
        date_time: 1688992800000,
        severity: "Critical",
        alert_name: "Zero viewership for the last 2 hrs",
        channel_name: "Channel 1",
        supply_demand_tags_affected: "Tag1",
        deviation: "85%",
        recurrence: "3",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: true,
    },
    {
        date_time: 1695072000000,
        severity: "High",
        alert_name: "Impression dropped below 30%",
        channel_name: "Channel 2",
        supply_demand_tags_affected: "Tag3",
        deviation: "72%",
        recurrence: "-",
        current_status: " ",
        unseen: true,
    },
    {
        date_time: 1675516800000,
        severity: "Medium",
        alert_name: "Viewership dropped below 20%",
        channel_name: "Channel 3",
        supply_demand_tags_affected: "Tag4",
        deviation: "63%",
        recurrence: "2",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: true,
    },
    {
        date_time: 1678214400000,
        severity: "Low",
        alert_name: "Zero impressions for the last 2 hrs",
        channel_name: "Channel 4",
        supply_demand_tags_affected: "Tag6",
        deviation: "55%",
        recurrence: "3",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1701196800000,
        severity: "Critical",
        alert_name: "Ad requests dropped below 30%",
        channel_name: "Channel 5",
        supply_demand_tags_affected: "Tag7",
        deviation: "95%",
        recurrence: "4",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: false,
    },
    {
        date_time: 1697260800000,
        severity: "High",
        alert_name: "Rise in VAST error rate",
        channel_name: "Channel 6",
        supply_demand_tags_affected: "Tag9",
        deviation: "75%",
        recurrence: "3",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1689907200000,
        severity: "Medium",
        alert_name: "Rise in empty VAST responses",
        channel_name: "Channel 7",
        supply_demand_tags_affected: "Tag10",
        deviation: "65%",
        recurrence: "2",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: false,
    },
    {
        date_time: 1680518400000,
        severity: "Low",
        alert_name: "Rise in empty responses due to wrappers",
        channel_name: "Channel 8",
        supply_demand_tags_affected: "Tag12",
        deviation: "50%",
        recurrence: "3",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1693545600000,
        severity: "Critical",
        alert_name: "Zero viewership for the last 2 hrs",
        channel_name: "Channel 9",
        supply_demand_tags_affected: "Tag2",
        deviation: "90%",
        recurrence: "2",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: true,
    },
    {
        date_time: 1697332800000,
        severity: "High",
        alert_name: "Impression dropped below 30%",
        channel_name: "Channel 10",
        supply_demand_tags_affected: "Tag13",
        deviation: "78%",
        recurrence: "3",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1683052800000,
        severity: "Medium",
        alert_name: "Viewership dropped below 20%",
        channel_name: "Channel 11",
        supply_demand_tags_affected: "Tag14",
        deviation: "67%",
        recurrence: "2",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: false,
    },
    {
        date_time: 1694054400000,
        severity: "Low",
        alert_name: "Zero impressions for the last 2 hrs",
        channel_name: "Channel 12",
        supply_demand_tags_affected: "Tag16",
        deviation: "52%",
        recurrence: "3",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1699872000000,
        severity: "Critical",
        alert_name: "Ad requests dropped below 30%",
        channel_name: "Channel 13",
        supply_demand_tags_affected: "Tag17",
        deviation: "88%",
        recurrence: "4",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: false,
    },
    {
        date_time: 1702387200000,
        severity: "High",
        alert_name: "Rise in VAST error rate",
        channel_name: "Channel 14",
        supply_demand_tags_affected: "Tag19",
        deviation: "73%",
        recurrence: "-",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1683686400000,
        severity: "Medium",
        alert_name: "Rise in empty VAST responses",
        channel_name: "Channel 15",
        supply_demand_tags_affected: "Tag20",
        deviation: "60%",
        recurrence: "2",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: true,
    },
    {
        date_time: 1678492800000,
        severity: "Low",
        alert_name: "Rise in empty responses due to wrappers",
        channel_name: "Channel 16",
        supply_demand_tags_affected: "Tag22",
        deviation: "58%",
        recurrence: "-",
        current_status: " ",
        unseen: true,
    },
    {
        date_time: 1700352000000,
        severity: "Critical",
        alert_name: "Zero viewership for the last 2 hrs",
        channel_name: "Channel 17",
        supply_demand_tags_affected: "Tag23",
        deviation: "91%",
        recurrence: "4",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: true,
    },
    {
        date_time: 1692307200000,
        severity: "High",
        alert_name: "Impression dropped below 30%",
        channel_name: "Channel 18",
        supply_demand_tags_affected: "Tag25",
        deviation: "74%",
        recurrence: "-",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1696032000000,
        severity: "Medium",
        alert_name: "Viewership dropped below 20%",
        channel_name: "Channel 19",
        supply_demand_tags_affected: "Tag26",
        deviation: "62%",
        recurrence: "3",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: true,
    },
    {
        date_time: 1692067200000,
        severity: "Low",
        alert_name: "Zero impressions for the last 2 hrs",
        channel_name: "Channel 20",
        supply_demand_tags_affected: "Tag28",
        deviation: "57%",
        recurrence: "3",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1688992800000,
        severity: "Critical",
        alert_name: "Zero viewership for the last 2 hrs",
        channel_name: "Channel 1",
        supply_demand_tags_affected: "Tag1",
        deviation: "85%",
        recurrence: "3",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: true,
    },
    {
        date_time: 1695072000000,
        severity: "High",
        alert_name: "Impression dropped below 30%",
        channel_name: "Channel 2",
        supply_demand_tags_affected: "Tag3",
        deviation: "72%",
        recurrence: "-",
        current_status: " ",
        unseen: true,
    },
    {
        date_time: 1675516800000,
        severity: "Medium",
        alert_name: "Viewership dropped below 20%",
        channel_name: "Channel 3",
        supply_demand_tags_affected: "Tag4",
        deviation: "63%",
        recurrence: "2",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: true,
    },
    {
        date_time: 1678214400000,
        severity: "Low",
        alert_name: "Zero impressions for the last 2 hrs",
        channel_name: "Channel 4",
        supply_demand_tags_affected: "Tag6",
        deviation: "55%",
        recurrence: "3",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1701196800000,
        severity: "Critical",
        alert_name: "Ad requests dropped below 30%",
        channel_name: "Channel 5",
        supply_demand_tags_affected: "Tag7",
        deviation: "95%",
        recurrence: "4",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: false,
    },
    {
        date_time: 1697260800000,
        severity: "High",
        alert_name: "Rise in VAST error rate",
        channel_name: "Channel 6",
        supply_demand_tags_affected: "Tag9",
        deviation: "75%",
        recurrence: "3",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1689907200000,
        severity: "Medium",
        alert_name: "Rise in empty VAST responses",
        channel_name: "Channel 7",
        supply_demand_tags_affected: "Tag10",
        deviation: "65%",
        recurrence: "2",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: false,
    },
    {
        date_time: 1680518400000,
        severity: "Low",
        alert_name: "Rise in empty responses due to wrappers",
        channel_name: "Channel 8",
        supply_demand_tags_affected: "Tag12",
        deviation: "50%",
        recurrence: "3",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1693545600000,
        severity: "Critical",
        alert_name: "Zero viewership for the last 2 hrs",
        channel_name: "Channel 9",
        supply_demand_tags_affected: "Tag2",
        deviation: "90%",
        recurrence: "2",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: true,
    },
    {
        date_time: 1697332800000,
        severity: "High",
        alert_name: "Impression dropped below 30%",
        channel_name: "Channel 10",
        supply_demand_tags_affected: "Tag13",
        deviation: "78%",
        recurrence: "3",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1683052800000,
        severity: "Medium",
        alert_name: "Viewership dropped below 20%",
        channel_name: "Channel 11",
        supply_demand_tags_affected: "Tag14",
        deviation: "67%",
        recurrence: "2",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: false,
    },
    {
        date_time: 1694054400000,
        severity: "Low",
        alert_name: "Zero impressions for the last 2 hrs",
        channel_name: "Channel 12",
        supply_demand_tags_affected: "Tag16",
        deviation: "52%",
        recurrence: "3",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1699872000000,
        severity: "Critical",
        alert_name: "Ad requests dropped below 30%",
        channel_name: "Channel 13",
        supply_demand_tags_affected: "Tag17",
        deviation: "88%",
        recurrence: "4",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: false,
    },
    {
        date_time: 1702387200000,
        severity: "High",
        alert_name: "Rise in VAST error rate",
        channel_name: "Channel 14",
        supply_demand_tags_affected: "Tag19",
        deviation: "73%",
        recurrence: "-",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1683686400000,
        severity: "Medium",
        alert_name: "Rise in empty VAST responses",
        channel_name: "Channel 15",
        supply_demand_tags_affected: "Tag20",
        deviation: "60%",
        recurrence: "2",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: true,
    },
    {
        date_time: 1678492800000,
        severity: "Low",
        alert_name: "Rise in empty responses due to wrappers",
        channel_name: "Channel 16",
        supply_demand_tags_affected: "Tag22",
        deviation: "58%",
        recurrence: "-",
        current_status: " ",
        unseen: true,
    },
    {
        date_time: 1700352000000,
        severity: "Critical",
        alert_name: "Zero viewership for the last 2 hrs",
        channel_name: "Channel 17",
        supply_demand_tags_affected: "Tag23",
        deviation: "91%",
        recurrence: "4",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: true,
    },
    {
        date_time: 1692307200000,
        severity: "High",
        alert_name: "Impression dropped below 30%",
        channel_name: "Channel 18",
        supply_demand_tags_affected: "Tag25",
        deviation: "74%",
        recurrence: "-",
        current_status: " ",
        unseen: false,
    },
    {
        date_time: 1696032000000,
        severity: "Medium",
        alert_name: "Viewership dropped below 20%",
        channel_name: "Channel 19",
        supply_demand_tags_affected: "Tag26",
        deviation: "62%",
        recurrence: "3",
        current_status: "Paused",
        paused_till: 1695072000000,
        unseen: true,
    },
    {
        date_time: 1692067200000,
        severity: "Low",
        alert_name: "Zero impressions for the last 2 hrs",
        channel_name: "Channel 20",
        supply_demand_tags_affected: "Tag28",
        deviation: "57%",
        recurrence: "3",
        current_status: " ",
        unseen: false,
    }
];

export const alertRulesTableContent = [
    {
        alert_name: "Impression dropped by 25% jhdbfsdf jshdf ksuhefr kuesh ",
        alert_type: "Trend",
        frequency: "Daily",
        severity: "Critical",
        status: "Enabled",
        actions: "Edit",
    },
    {
        alert_name: "Zero Ad requests for the last 6 hours",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Clone", // or "Edit", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 30% Slow Query Alert ksdhfshdfkdsfhkdus hkuehrkuehksurktdktuh",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "Critical", // or "High"
        status: "Disabled", // or "Enabled"
        actions: "Disable", // or "Edit", "Clone", "Delete"
    },
    {
        alert_name: "Impression dropped by 10%",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Delete", // or "Edit", "Clone", "Disable"
    },
    {
        alert_name: "Zero Ad requests for the last 2 hours",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Edit", // or "Clone", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 25%",
        alert_type: "Trend",
        frequency: "Daily",
        severity: "Critical",
        status: "Enabled",
        actions: "Edit",
    },
    {
        alert_name: "Zero Ad requests for the last 6 hours",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Clone", // or "Edit", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 30% Slow Query Alert ksdhfshdfkdsfhkdus hkuehrkuehksurktdktuh",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "Critical", // or "High"
        status: "Disabled", // or "Enabled"
        actions: "Disable", // or "Edit", "Clone", "Delete"
    },
    {
        alert_name: "Impression dropped by 10%",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Delete", // or "Edit", "Clone", "Disable"
    },
    {
        alert_name: "Zero Ad requests for the last 2 hours",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Edit", // or "Clone", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 25%",
        alert_type: "Trend",
        frequency: "Daily",
        severity: "Critical",
        status: "Enabled",
        actions: "Edit",
    },
    {
        alert_name: "Zero Ad requests for the last 6 hours",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Clone", // or "Edit", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 30% Slow Query Alert ksdhfshdfkdsfhkdus hkuehrkuehksurktdktuh",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "Critical", // or "High"
        status: "Disabled", // or "Enabled"
        actions: "Disable", // or "Edit", "Clone", "Delete"
    },
    {
        alert_name: "Impression dropped by 10%",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Delete", // or "Edit", "Clone", "Disable"
    },
    {
        alert_name: "Zero Ad requests for the last 2 hours",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Edit", // or "Clone", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 25%",
        alert_type: "Trend",
        frequency: "Daily",
        severity: "Critical",
        status: "Enabled",
        actions: "Edit",
    },
    {
        alert_name: "Zero Ad requests for the last 6 hours",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Clone", // or "Edit", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 30% Slow Query Alert ksdhfshdfkdsfhkdus hkuehrkuehksurktdktuh",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "Critical", // or "High"
        status: "Disabled", // or "Enabled"
        actions: "Disable", // or "Edit", "Clone", "Delete"
    },
    {
        alert_name: "Impression dropped by 10%",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Delete", // or "Edit", "Clone", "Disable"
    },
    {
        alert_name: "Zero Ad requests for the last 2 hours",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Edit", // or "Clone", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 25%",
        alert_type: "Trend",
        frequency: "Daily",
        severity: "Critical",
        status: "Enabled",
        actions: "Edit",
    },
    {
        alert_name: "Zero Ad requests for the last 6 hours",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Clone", // or "Edit", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 30% Slow Query Alert ksdhfshdfkdsfhkdus hkuehrkuehksurktdktuh",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "Critical", // or "High"
        status: "Disabled", // or "Enabled"
        actions: "Disable", // or "Edit", "Clone", "Delete"
    },
    {
        alert_name: "Impression dropped by 10%",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Delete", // or "Edit", "Clone", "Disable"
    },
    {
        alert_name: "Zero Ad requests for the last 2 hours",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Edit", // or "Clone", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 25%",
        alert_type: "Trend",
        frequency: "Daily",
        severity: "Critical",
        status: "Enabled",
        actions: "Edit",
    },
    {
        alert_name: "Zero Ad requests for the last 6 hours",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Clone", // or "Edit", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 30% Slow Query Alert ksdhfshdfkdsfhkdus hkuehrkuehksurktdktuh",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "Critical", // or "High"
        status: "Disabled", // or "Enabled"
        actions: "Disable", // or "Edit", "Clone", "Delete"
    },
    {
        alert_name: "Impression dropped by 10%",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Delete", // or "Edit", "Clone", "Disable"
    },
    {
        alert_name: "Zero Ad requests for the last 2 hours",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Edit", // or "Clone", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 25%",
        alert_type: "Trend",
        frequency: "Daily",
        severity: "Critical",
        status: "Enabled",
        actions: "Edit",
    },
    {
        alert_name: "Zero Ad requests for the last 6 hours",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Clone", // or "Edit", "Disable", "Delete"
    },
    {
        alert_name: "Impression dropped by 30%",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "Critical", // or "High"
        status: "Disabled", // or "Enabled"
        actions: "Disable", // or "Edit", "Clone", "Delete"
    },
    {
        alert_name: "Impression dropped by 10%",
        alert_type: "Spot", // or "Trend"
        frequency: "Hourly", // or "Daily"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Delete", // or "Edit", "Clone", "Disable"
    },
    {
        alert_name: "Zero Ad requests for the last 2 hours",
        alert_type: "Trend", // or "Spot"
        frequency: "Daily", // or "Hourly"
        severity: "High", // or "Critical"
        status: "Enabled", // or "Disabled"
        actions: "Edit", // or "Clone", "Disable", "Delete"
    },
];

// {
//     key: "metric",
//         label: "Metrics",
//     width: "100px",
// },

export const issueDetailTableContent = [
    {
        metric: "Ad requests",
        triggered_value: "9714",
        previous_value: "15416",
        deviation: "-37%",
    },
    {
        metric: "Viewership",
        triggered_value: "9714",
        previous_value: "15416",
        deviation: "-37%",
    },
    {
        metric: "Response rate",
        triggered_value: "80%",
        previous_value: "82%",
        deviation: "-2%",
    },
    {
        metric: "Fill rate",
        triggered_value: "5%",
        previous_value: "6%",
        deviation: "-1%",
    },

];

