import React from 'react';
import './index.css'
import CrossIcon from "../../assests/icons/crossIcon";
import DialogActions from "@mui/material/DialogActions";
import {Button} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";

const CustomDialogBox = (props) => {
    return (
        <Dialog
            open={props?.isDialogOpen}
            onClose={() => props?.handleCloseDialog(false)}
        >
            <div className="custom-dialog-title">
                <div>{props?.dialogData.dialogTitle}</div>
                <div><CrossIcon handleCloseDialog={() => props?.handleCloseDialog(false)}/></div>
            </div>
            <div className="tab-divider-dialog"></div>
            <div className="custom-dialog-content">
                <div> {props?.dialogData.dialogDescription1} </div>
                <div> {props?.dialogData.dialogDescription2} </div>
            </div>
            <div className="tab-divider-dialog"></div>
            {!props?.isApproving ? (
                <DialogActions>
                    <Button size="default" className='report-cancel-button' onClick={() => props?.handleCloseDialog(false)}> {props?.dialogData?.cancelButtonText || "Cancel" } </Button>
                    {props?.allowSubmit && <Button size="default"
                             className={props?.dialogData?.actionButtonColor === "red" ? "red-button" : "orange-button"}
                             onClick={props?.handleAction}>{props?.dialogData?.actionButtonText || "Yes, go ahead"}</Button>}
                    {!props?.allowSubmit && <Button disabled size="default" className={ props?.dialogData?.actionButtonColor === "red" ? "red-button" : "orange-button" } onClick={props?.handleAction}>
                        <CircularProgress
                            size={20}
                            disableShrink
                            color="inherit"
                        />
                      </Button>
                    }
                </DialogActions>
            ) : (
                <DialogActions>
                    <CircularProgress size={34} disableShrink />
                </DialogActions>
            ) }
        </Dialog>
    )
}


export default CustomDialogBox;
