export const AlertsStatusIcons = (type="") => {
    if (type.toLowerCase() === "enabled" || type.toLowerCase() === "active") {
        return (
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Icon">
                    <path id="Vector"
                          d="M8.5 14C5.1862 14 2.5 11.3138 2.5 8C2.5 4.6862 5.1862 2 8.5 2C11.8138 2 14.5 4.6862 14.5 8C14.5 11.3138 11.8138 14 8.5 14ZM8.5 12.8C9.77304 12.8 10.9939 12.2943 11.8941 11.3941C12.7943 10.4939 13.3 9.27304 13.3 8C13.3 6.72696 12.7943 5.50606 11.8941 4.60589C10.9939 3.70571 9.77304 3.2 8.5 3.2C7.22696 3.2 6.00606 3.70571 5.10589 4.60589C4.20571 5.50606 3.7 6.72696 3.7 8C3.7 9.27304 4.20571 10.4939 5.10589 11.3941C6.00606 12.2943 7.22696 12.8 8.5 12.8ZM7.65169 10.9294L6.37879 9.65649L5.10589 8.38359L5.95429 7.53519L7.65169 9.23259L11.0453 5.83839L11.8943 6.68679L7.65169 10.9294Z"
                          fill="#82C77F"/>
                </g>
            </svg>
        )
    } else if (type.toLowerCase() === "disabled" || type.toLowerCase() === "pending") {
        return (
            <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="forbid-line">
                    <path id="Vector"
                          d="M8.49992 14.6668C4.81792 14.6668 1.83325 11.6822 1.83325 8.00016C1.83325 4.31816 4.81792 1.3335 8.49992 1.3335C12.1819 1.3335 15.1666 4.31816 15.1666 8.00016C15.1666 11.6822 12.1819 14.6668 8.49992 14.6668ZM8.49992 13.3335C9.91441 13.3335 11.271 12.7716 12.2712 11.7714C13.2713 10.7712 13.8333 9.41465 13.8333 8.00016C13.8333 6.58567 13.2713 5.22912 12.2712 4.22893C11.271 3.22873 9.91441 2.66683 8.49992 2.66683C7.08543 2.66683 5.72888 3.22873 4.72868 4.22893C3.72849 5.22912 3.16659 6.58567 3.16659 8.00016C3.16659 9.41465 3.72849 10.7712 4.72868 11.7714C5.72888 12.7716 7.08543 13.3335 8.49992 13.3335V13.3335ZM6.18192 4.7395L11.7606 10.3182C11.5009 10.6826 11.1823 11.0012 10.8179 11.2608L5.23925 5.68216C5.49897 5.31798 5.81756 4.99963 6.18192 4.74016V4.7395Z"
                          fill="#999999"/>
                </g>
            </svg>
        )
    } else {
        return (
            <></>
        )
    }
}
