import { useState, useEffect } from "react";
import Login from "./Screens/Login";
import Container from "./Container";
import { Box } from "@mui/material";
import './App.css';
import { getLocalStorageData, setLocalStorageData } from "./utils/localStorageHelper";
import {
    INITIAL_PAGE,
    JWT_TOKEN_KEY,
    ONGOING_API_CALL_KEY,
    REACT_APP_SENTRY_DSN,
    USER_DATA_KEY,
    USER_VALIDATION_ON_GOING, WARNING_TYPE
} from "./Constant";
import {calculateTimeFromNow, getCurrentTime, getInitialPage, isEmpty} from "./utils/helper";
import { useAuth0 } from "@auth0/auth0-react";
import LazyLoading from "./Components/CommonScreens/LazyLoading";
import * as Sentry from "@sentry/react";
import useWebStorageHelper from "./utils/sessionStorageHelper";
import useCookieStorageHelper from "./utils/cookieStorageHelper";
import Notification from "./Components/Notification";
import {updateAmagiFridayUserStatus} from "./api/updateAmagiFridayUserStatus";

Sentry.init({
    dsn: REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost"],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


function App() {
    const [isUserAuthenticated, setUserAuthenticated] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [showNotifications, setShowNotifications] = useState(false);
    const [notificationsMsg, setNotificationsMsg] = useState("");
    const [userValidationOnGoing, setUserValidationOnGoing] = useState(true);
    const [userData, setUserData] = useState({});
    const [currentPath, setCurrentPath] = useState("");

    const {
        isAuthenticated,
        loginWithRedirect,
        getAccessTokenSilently,
        user,
    } = useAuth0();

    useEffect(() => {
        const initializeAuthentication = async () => {
            try {
                setUserValidationOnGoing(true)
                const storedUserData = useWebStorageHelper.getItem(USER_DATA_KEY);
                const currentEpochTime = getCurrentTime();
                const isValidationOngoing = getLocalStorageData(USER_VALIDATION_ON_GOING, false) === "true";
                const userRoles = storedUserData?.user_roles || []
                const getCurrentPath = window.location.pathname

                if (storedUserData?.expires_on > currentEpochTime && !isValidationOngoing && userRoles.length > 0) {
                    setUserData(storedUserData)
                    setUserAuthenticated(true);
                    setIsLoading(false);
                    setUserValidationOnGoing(false)
                    let initialPage = getInitialPage(userRoles)
                    if (getCurrentPath === "/") {
                        window.history.pushState('/dashboard/', '', initialPage);
                    }
                } else if (isValidationOngoing && isAuthenticated) {
                    await validateToken();
                } else {
                    setUserAuthenticated(false);
                    setIsLoading(false);
                    setUserValidationOnGoing(false)
                    useWebStorageHelper.removeItem(USER_DATA_KEY);
                    //window.history.replaceState("/dashboard", '', '/login');
                }

                setCurrentPath(getCurrentPath)
            } catch (error) {
                console.error("Error during authentication initialization:", error);
                setUserAuthenticated(false);
                setIsLoading(false);
                setUserValidationOnGoing(false);
                setShowNotifications(true);
                setNotificationsMsg("Unable to authenticate");
            }
        };

        initializeAuthentication();
    }, [isAuthenticated]);

    const validateToken = async () => {
        try {
            const token = await getAccessTokenSilently();
            const futureEpochTime = calculateTimeFromNow();
            const userRoles = user?.user_roles || [];

            if (!isEmpty(token) && !isEmpty(user) && userRoles.length > 0) {
                useCookieStorageHelper.setItemCookie(JWT_TOKEN_KEY, token, 1, true, 'Strict');
                useWebStorageHelper.setItem(USER_DATA_KEY, { ...user, expires_on: futureEpochTime });
                setLocalStorageData(USER_VALIDATION_ON_GOING, false, false);
                setLocalStorageData(ONGOING_API_CALL_KEY, false, false);
                let initialPage = getInitialPage(userRoles);
                setUserValidationOnGoing(false);
                updateAmagiFridayUserStatus(user?.user_email);
                setTimeout(() => {
                    window.location.href = initialPage;
                }, 500);
            }else {
                setUserAuthenticated(false);
                setIsLoading(false);
                useWebStorageHelper.removeItem(USER_DATA_KEY);
                setUserValidationOnGoing(false)
                setShowNotifications(true)
                setNotificationsMsg("User don't have permissions");
            }
        } catch (error) {
            console.error("Error validating token:", error);
            setUserAuthenticated(false);
            setIsLoading(false);
            setUserValidationOnGoing(false)
            setShowNotifications(true)
            setNotificationsMsg("Unable to authenticate");
        }
    };

    const onClickLoginButton = () => {
        setLocalStorageData(USER_VALIDATION_ON_GOING, false, true);
        loginWithRedirect();
    };

    const closeNotifications = () => {
        setShowNotifications(false)
        setNotificationsMsg("");
    }

    return (
        <Sentry.ErrorBoundary fallback={<div>Error occurred while rendering the application.</div>}>
            <Box className="main-component">
                {isLoading ? (
                    <LazyLoading />
                ) : isUserAuthenticated ? (
                    <Container
                        userData={userData}
                        isAuthenticated={isUserAuthenticated}
                        currentPath={currentPath}
                    />
                ) : (
                    <Login
                        onClickLoginButton={onClickLoginButton}
                        isLoading={userValidationOnGoing}
                    />
                )}
            </Box>
            <Notification
                message={notificationsMsg}
                handleClose={closeNotifications}
                type={WARNING_TYPE}
                open={showNotifications}
            />
        </Sentry.ErrorBoundary>
    );
}

export default App;
