export const alertsTableConfig = [
    {
        key: "alert_timestamp",
        label: "DATE/TIME",
        width: "100px",
        sortIcon: true,
        type: "date"
    },
    {
        key: "severity",
        label: "SEVERITY",
        width: "100px",
        type: "severity",
    },
    {
        key: "alert_name",
        label: "ALERT NAME",
        width: "100px",
        clickable: true,
        textColor: "#7FB8F0",
        maxAllowedChars: 35,
        showTooltip: true
    },
    {
        key: "channel_name",
        label: "CHANNEL",
        width: "100px",
        textColor: "#7FB8F0"
    },
    {
        key: "entity_name",
        label: "SUPPLY/DEMAND TAGS AFFECTED",
        width: "100px",
        textColor: "#7FB8F0"
    },
    {
        key: "deviation",
        label: "DEVIATION",
        width: "100px"
    },
    {
        key: "recurrence",
        label: "RECURRENCE",
        width: "100px",
        infoIcon: true,
        infoText: "Recurrence window is 30 days."
    },
    {
        key: "pause_end_timestamp",
        label: " ",
        type: "current_status",
        width: "150px"
    },
    {
        key: "actions",
        label: " ",
        allowActions: true,
        width: "0px",
        options: [
            "Pause alert",
            "Resume alert"
        ],
    },
];
