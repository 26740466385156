import * as React from 'react';
import {Fragment} from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import CircleBox from "../../CircleBox";
import "./index.css"
import {Grid} from "@mui/material";
import LogoutIcon from "../../../assests/icons/logout";
import UserIdentityIcon from "../../../assests/icons/userIdentityIcon";
import {logOutUser} from "../../../utils/localStorageHelper";
import {AMAGI_FRIDAY_MASTER_ADMIN_ROLE} from "../../../Constant";

export default function BasicPopover(props) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleNavigation = (path) => {
        window.location.href = path;
    }

    return (
        <Grid className="amg-user-info-popover">
            <div onClick={handleClick}>
                <CircleBox nameIntials={props.Initial} />
            </div>
            <Popover
                className="user-detail-popover"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid container sx={{padding: "16px 16px", borderBottom: "1px solid  rgba(255, 255, 255, 0.08)"}}>
                    <Grid item={1}><CircleBox nameIntials={props.Initial}/></Grid>

                    <Grid item={11}>
                        <div className="user-info-div"><Typography
                            className="userName-dropdown">{props.userData.user_name}</Typography>
                            <Typography className="userEmail-dropdown">{props.userData.user_email}</Typography>
                        </div>

                    </Grid>

                </Grid>
                {props?.userData?.user_roles?.includes(AMAGI_FRIDAY_MASTER_ADMIN_ROLE) && (
                    <Fragment>
                        <Grid container className="logout-div">
                            <Grid item={3} className="logout-grid">
                                <UserIdentityIcon/>
                            </Grid>
                        <Grid item={9} className="logout-grid">
                            <Typography onClick={() => handleNavigation("/dashboard/user-management")}
                                        className="logout-typography">User management
                            </Typography>
                        </Grid>
                    </Grid>
                    <div className="vector-full-width"></div>
                    </Fragment>
                )}
                <Grid container className="logout-div" >
                    <Grid item={3} onClick={logOutUser} className="logout-grid">
                        <LogoutIcon  />
                    </Grid>
                    <Grid item={9} onClick={logOutUser} className="logout-grid">
                        <Typography className="logout-typography">Logout</Typography>
                    </Grid>
                </Grid>
            </Popover>
        </Grid>
    );
}
