import { isArray } from "@apollo/client/utilities";
import {
    ADMIN_INITIAL_PAGE,
    ADMIN_UI_USER_ROLE, ADOPS_INITIAL_PAGE,
    ADOPS_UI_USER_ROLE,
    BUISNESS_INITIAL_PAGE,
    BUISNESS_UI_USER_ROLE,
    DATE_THRESHOLD_DASHBOARD,
    JWT_TOKEN_KEY,
    MONTH_CHECK_DATA_PRESENT,
    ONB_INITIAL_PAGE,
    ONB_UI_USER_ROLE,
    Q1_MONTTHS_WISE,
    Q2_MONTTHS_WISE,
    Q3_MONTTHS_WISE,
    Q4_MONTTHS_WISE,
    Q_FOUR_VALUE,
    Q_ONE_VALUE,
    Q_THREE_VALUE,
    Q_TWO_VALUE,
    USER_DATA_KEY, USER_ROLE_TO_PATH_MAPPING,
    USER_ROLES_PERMISSIONS,
    YEAR_CHECK_DATA_PRESENT
} from "../Constant";
import useWebStorageHelper from "./sessionStorageHelper";
import useCookieStorageHelper from "./cookieStorageHelper";


export function calculateTimeFromNow(seconds = 86400) {
    const now = new Date();
    const futureTime = new Date(now.getTime() + seconds * 1000);
    return Math.floor(futureTime.getTime() / 1000);
}

export const getCurrentTime = () => {
    return Math.floor(Date.now() / 1000);
};

export const getLimitedData = (data, limit, offset) => {
    return data.slice(offset, limit)
}

export const formatModelsData = (data) => {
    const formatData = {}

    formatData.platform_name = data?.platform_name
    formatData.model_name = data?.model_name
    formatData.supply_partner_id = data?.supply_partner_id
    formatData.sample_ad_tag_url = data?.sample_ad_tag_url
    formatData.platform_vast_endpoint_url = data?.sample_platform_partner_demand_config?.vast_endpoint_url
    formatData.content_vast_endpoint_url = data?.sample_content_partner_demand_config?.vast_endpoint_url
    formatData.amagi_vast_endpoint_url = data?.sample_amagi_partner_demand_config?.vast_endpoint_url
    formatData.model_id = data?.model_id
    formatData.platform_id = data?.platform_id

    return formatData
}

export const getEpochData = (epoch = 0) => {
    const timeStamp = parseInt(epoch)
    // Create a new Date object with the timestamp
    const date = new Date(timeStamp);

    const months = [
        "January", "February", "March", "April",
        "May", "June", "July", "August",
        "September", "October", "November", "December"
    ];

    // Extract individual components (year, month, day, hours, minutes, seconds)
    //const year = date.getFullYear();
    const month = date.getMonth(); // Months are zero-based
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    return `${day} ${months[month]}, ${hours}:${minutes < 10 ? '0' + minutes : minutes} ${ampm}`
}

export const replaceUnderscoresWithSpaces = (str) => {
    if (str) {
        return str.replace(/_/g, ' ');
    }
}

//Extract ad server
export const extractAdServer = (url) => {
    if(!isEmpty(url)) {
        const startIndex = url.indexOf('https://') + 'https://'.length;
        const endIndex = url.indexOf('/', startIndex);

        if (startIndex !== -1 && endIndex !== -1) {
            const substring = url.substring(startIndex, endIndex);
            const words = substring.split('.');
            let largestWord = '';

            words.forEach(word => {
                if (word.length > largestWord.length) {
                    largestWord = word;
                }
            });

            return largestWord;
        } else {
            return null; // Invalid URL format

        }
    }else {
        return null;
    }

};

export const isEmpty = (data = "") => {
    if (data && data.length > 0) {
        return false
    }


    if (data && isArray(data) && data.length > 0) {
        return false
    }

    if (data && typeof data === "object" && Object.keys(data).length > 0) {
        return false
    }

    return true
}

export const removeDashFromString = (string = "") => {
    return string?.replace(/_/g, " ") || ""
}

export const getTStag = (inputString) => {
    const index = inputString?.indexOf('-');
    if (index !== -1) {
        return inputString?.substring(0, index).trim();
    } else {
        return inputString?.trim(); // Return the whole string if there is no dash
    }
}
export const formatDate = (inputDate) => {
    const dateObj = new Date(inputDate);
    let commaCount = 0

    // Format the date
    const formattedDate = dateObj.toLocaleString('en-US', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
        timeZone: 'UTC'
    })

    return formattedDate.split('').map(char => {
        if (char === ',') {
            commaCount++;
            if (commaCount === 2) return ' ';
        }
        return char;
    }).join('');
}


export const getImageName = (string = "") => {
    return !isEmpty(string) ? string.split(' ').map(word => word?.[0]?.toUpperCase())?.join('') : "NA"
}


export const reduceString = (str,maxLength = 28) => {
    if (typeof str !== 'string') {
        return str; // Return unchanged if not a string
    }

    if (str.length <= maxLength) {
        return str;
    } else {
        const reducedStr = str.substring(0, maxLength - 3); // Subtract 3 for the ellipsis
        return reducedStr + '...';
    }
}

export const getItemFromArray = (id = "", array = [], key = "", returnKey = "") => {
    const findElem = array.find((data) => data[key] === id )
    if(findElem?.[returnKey]){
        return findElem[returnKey]
    }else {
        return null
    }
}

export function getMonthName(monthIndex) {
    let monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return monthNames[monthIndex];
}


export function getCurrentDateWithMonthYear() {
    let currentTime = new Date();
    let currentMonth = currentTime.getMonth() + 1; // JavaScript months are 0-based, so we add 1
    let currentYear = currentTime.getFullYear();
    let currentDate = currentTime.getDate();

    if (currentDate !== DATE_THRESHOLD_DASHBOARD) {
        // Delay of 1 day
        currentDate -= 1;
    } else {
        // Special case: if the current date matches the threshold, move to the previous month's last day
        currentMonth -= 1;

        // Handle if month becomes 0 (i.e., previous year)
        if (currentMonth === 0) {
            currentMonth = 12; // December
            currentYear -= 1;
        }

        // Get the number of days in the previous month
        currentDate = getLastDayOfMonth(currentYear, currentMonth);
    }

    // Ensure month and day have correct formatting (two digits)
    let monthString = currentMonth.toString().padStart(2, '0'); // Add leading zero only if necessary
    let dateString = currentDate.toString().padStart(2, '0'); // Add leading zero only if necessary

    // Return properly formatted date string
    return `${currentYear}-${monthString}-${dateString}`;
}

// Helper function to get the last day of a month
function getLastDayOfMonth(year, month) {
    return new Date(year, month, 0).getDate(); // `new Date(year, month, 0)` gets the last day of the previous month
}


export function getLast6Months() {
    let months = [];
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth() + 1;
    let currentYear = currentDate.getFullYear();
    let todayDate = currentDate.getDate()

    // Format the current month as a two-digit string
    let monthString = currentMonth.toString().padStart(2, '0');

    // Push the current month if the current date is not the 1st
    if(todayDate !== 1){
        months.push({
            'label': getMonthName(currentMonth - 1) + ' ' + currentYear,  // `currentMonth - 1` because `getMonthName` expects 0-based month
            "value": `${currentYear}-${monthString}-01`,
            'CurrentMonth': true
        });
    }

    //Push the previous 6 months
    for (let i = 1; i < 7; i++) {
        let prevMonth = new Date(currentYear, currentMonth - 1 - i, 1);  // Go back `i` months
        const getPrevMonthNumeric = prevMonth.getMonth() + 1; // Correct for 0-based month
        let prevMonthString = getPrevMonthNumeric.toString().padStart(2, '0'); // Ensure two digits

        if(prevMonth.getFullYear() === YEAR_CHECK_DATA_PRESENT && getPrevMonthNumeric > MONTH_CHECK_DATA_PRESENT){
            months.push({ "label": getMonthName(prevMonth.getMonth()) + ' ' + prevMonth.getFullYear(), "value": `${prevMonth.getFullYear()}-${prevMonthString}-01`,'CurrentMonth':false });
        }
    }

    return months;
}

export function formatNumber(num) {
    if(typeof num === "number"){
        return num.toLocaleString();
    }else {
        let newNum = parseInt(num);
        return newNum.toLocaleString();
    }

}

export function isNegative(num) {
    return num < 0;
}

export function removeNegative(num) {
    return Math.abs(num);
}

export function formatRevenue(value = 0) {
    return `$${value.toLocaleString()}`;
}

export const addPercentage = (value = 0) => {
    return `${value}%`;
}

export const getDateLabel = (value) => {
    let date = new Date(value);
    const options = { month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options)
}

export function getUniqueData(data) {
    const uniqueKeys = new Set();
    return data.filter(item => {
        const key = item.amagi_key;
        if (!uniqueKeys.has(key)) {
            uniqueKeys.add(key);
            return true;
        }
        return false;
    });
}


export const checkNegative = (value = 0) => {
    if(typeof value === "number" && (isNaN(value) || value <= 0  || value == "Null" || value == "undefined" || value == undefined || value == null || value <= 999999999 )) {
        return true;
    }

    return typeof value === "string" && (value == "Null" || value == "undefined" || value == null);

}

export const groupPartners = (data = [], groupData = [], key = "") =>{
    let newGroupedData = []
    if(data.length > 0 && groupData.length > 0){
        groupData.map((id) => {
            const findData = data.find(item => item?.[key] === id && data)
            if(findData){
                newGroupedData.push(findData);
            }

            return true
        })
        data.map((item, index) => {
            if(groupData.indexOf(item?.[key]) < 0){
                newGroupedData.push(item)
            }

            return true
        })
    }

    return newGroupedData
}

export const removeFractions = (value) => {
    return Math.ceil(value * 100) / 100;
}

export const getDefaultSupplyPartner = (id = 0, name = "", date = "") => {
    return(
        {
            "ad_requests": 0,
            "cost": 0,
            "date": date,
            "ecpm": 0,
            "fill_rate": 0,
            "impressions": 0,
            "inventory_type": "non_o_o",
            "net_revenue": 0,
            "margin_per": 0,
            "pod_time_fill_rate": 0,
            "pod_time_filled": 0,
            "pod_time_requested": 0,
            "revenue": 0,
            "rpm": 0,
            "supply_partner_id": id,
            "supply_partner_name": name,
            "timeouts": 0
        }
    )
}

export const getDefaultDemandPartner = (id = 0, name = "", date = "") => {
    return(
        {
            "bid_rate": 0,
            "bids": 0,
            "cost": 0,
            "date": date,
            "demand_partner_id": id,
            "demand_partner_name": name,
            "erpm": 0,
            "errors": 0,
            "impressions": 0,
            "margin": 0,
            "margin_per": 0,
            "requests": 0,
            "revenue": 0,
            "timeouts": 0,
            "use_rate": 0,
            "win_rate": 0,
            "wins": 0
        }
    )
}

export const  getLastDateOfMonth = (dateString) => {
    // Extract year and month from the dateString
    const [year, month] = dateString.split('-').map(Number);

    // Create a new date object set to the first day of the next month
    const firstDayNextMonth = new Date(year, month, 1);

    // Subtract one day to get the last day of the given month
    const lastDayOfMonth = new Date(firstDayNextMonth - 1);

    // Format the date as "YYYY-MM-DD"
    const lastDate = lastDayOfMonth.toISOString().split('T')[0];

    return lastDate;
}

export const getCurrentQuarter = (currentMonth = 0) => {
    if(currentMonth === 1 || currentMonth === 2 || currentMonth === 3 ){
        return {"value": Q_FOUR_VALUE, "months": [1, 2, 3], "label": Q4_MONTTHS_WISE, "header": "FY Q4 ‘25", "year": 2025, "month_label": ["Jan", "Feb", "Mar"]}
    }

    if(currentMonth === 4 || currentMonth === 5 || currentMonth === 6){
        return {"value": Q_ONE_VALUE, "months": [4, 5, 6], "label": Q1_MONTTHS_WISE, "header": "FY Q1 ‘24", "year": 2024 , "month_label":  ["Apr", "May", "Jun"]}
    }

    if(currentMonth === 7 || currentMonth === 8 || currentMonth === 9 ){
        return {"value": Q_TWO_VALUE, "months": [7, 8, 9], "label": Q2_MONTTHS_WISE, "header": "FY Q2 ‘24", "year": 2024, "month_label": ["Jul", "Aug", "Sep"]}
    }

    if(currentMonth === 10 || currentMonth === 11 || currentMonth === 12){
        return {"value": Q_THREE_VALUE, "months": [10, 11, 12], "label": Q3_MONTTHS_WISE, "header": "FY Q3 ‘24", "year": 2024, "month_label": ["Oct", "Nov", "Dec"]}
    }

}

export function formatNumberToMillion(num) {
    if (num >= 1000000) {
        let formattedNum = (num / 1000000).toFixed(1);
        // Remove trailing .0 if present
        if (formattedNum.endsWith('.0')) {
            formattedNum = formattedNum.slice(0, -2);
        }
        return formattedNum + 'M';
    } else if (num >= 1000) {
        let formattedNum = (num / 1000).toFixed(1);
        // Remove trailing .0 if present
        if (formattedNum.endsWith('.0')) {
            formattedNum = formattedNum.slice(0, -2);
        }
        return formattedNum + 'K';
    }

    if(num){
        return num.toString();
    }

    return `0M`;
}

export function calculateFivePercentPlusAndMinus(number) {
    let fivePercent = number * 0.05;
    let fivePercentPlus = number + fivePercent;
    let fivePercentMinus = number - fivePercent;
    return {
        plus: fivePercentPlus,
        minus: fivePercentMinus
    };
}

export const getMonthIndex = (month) => {
    const monthIndex = {
        "Jan": 1,
        "Feb": 2,
        "Mar": 3,
        "Apr": 4,
        "May": 5,
        "Jun": 6,
        "Jul": 7,
        "Aug": 8,
        "Sep": 9,
        "Oct": 10,
        "Nov": 11,
        "Dec": 12,
    }

    return monthIndex[month]
}

export const getDayUtilised = (startMonth = 0, startYear = 0, endMonth = 0, endYear = 0) => {
    // Initialize a counter for the total number of days completed
    let totalDaysCompleted = 0;

    // Today's date for comparison
    const today = new Date();
    const currentYear = today.getFullYear();
    const currentMonth = today.getMonth() + 1; // JavaScript months start at 0
    const currentDate = today.getDate();

    // Iterate over each month from the start month/year to the end month/year
    for (let year = startYear; year <= endYear; year++) {
        // Determine the starting and ending months for the current year
        let startM = (year === startYear) ? startMonth : 1;
        let endM = (year === endYear) ? endMonth : 12;

        for (let month = startM; month <= endM; month++) {
            // Calculate the total days in the current month
            const totalDaysInMonth = new Date(year, month, 0).getDate();

            if (year < currentYear || (year === currentYear && month < currentMonth)) {
                // If the month is in the past, add all days of the month
                totalDaysCompleted += totalDaysInMonth;
            } else if (year === currentYear && month === currentMonth) {
                // If it's the current month, add the days completed so far
                totalDaysCompleted += currentDate;
                break; // Stop iterating once reaching the current month
            }
        }
    }

    return totalDaysCompleted;
}

export const getCompletedDaysForAQuarter = (quarter = "", year = 2024) => {
    let completedDays = 0
    switch (quarter){
        case "Q1":
            completedDays = getDayUtilised(4, year, 6, year)
            break;
        case "Q2":
            completedDays = getDayUtilised(7, year, 9, year)
            break
        case "Q3":
            completedDays = getDayUtilised(10, year, 12, year)
            break
        case "Q4":
            completedDays = getDayUtilised(1, year, 3, year)
            break
    }

    return completedDays;
}
export const formatTheDate = (isoDateString) => {
    if(isoDateString) {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const date = new Date(isoDateString);

        const year = date.getFullYear();
        const monthIndex = date.getMonth();
        const month = months[monthIndex];
        const day = date.getDate();
        const hours = date.getHours();
        const minutes = date.getMinutes();

        // Add leading zeros if needed
        const formattedDay = day < 10 ? '0' + day : day;
        const formattedHours = hours < 10 ? '0' + hours : hours;
        const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

        const formattedDate = `${month} ${formattedDay}, ${year} ${formattedHours}:${formattedMinutes}`;

        return formattedDate;
    }
    else{
        return "N/A"
    }
};

export const checkIsCurrentMonth = (month = 1) => {
    let currentDate = new Date();
    let currentMonth = currentDate.getMonth() + 1;

    return currentMonth !== month;

}

export const formatDateForActivityLog = (inputDate) => {
    // Create a Date object from the input date string
    const date = new Date(inputDate);

    // Format the date into "MMM DD, YYYY" format
    const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
    });

    // Format the time into "HH:mm" format
    const formattedTime = date.toLocaleTimeString('en-US', {
        hour: 'numeric',
        minute: '2-digit'
    });

    // Combine date and time into the desired format
    const formattedDateTime = `${formattedDate} ${formattedTime}`;

    return formattedDateTime;
};

export const getCurrentMonthAndYearString = () => {
    let currentDate = new Date();
    let currentMonthString = getMonthName(currentDate.getMonth());
    let currentYear = currentDate.getFullYear();

    return `${currentMonthString} ${currentYear}`;
}
export function convertToNewLabelValueFormat(data) {
    // Initialize an empty array to store the transformed objects
    let transformedData = [];

    // Iterate over each object in the input data array
    data.forEach(item => {
        // Extract the value from the original object
        let countryValue = item.value;

        // Check if the value is not empty
        if (countryValue) {
            // Create a new object with label and value fields
            let transformedItem = {
                "label": countryValue, // Use the same value for label
                "value": countryValue  // Use the same value for value
            };

            // Push the transformed object into the new array
            transformedData.push(transformedItem);
        }
    });

    // Return the transformed data array
    return transformedData;
}

 export function RemoveDecimalUSANumberFormat(num) {
    let integerPart = Math.round(num);

    // Convert the integer part to a string
    let numStr = integerPart.toString();

    // Format the number with commas
    let formattedNumber = numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return formattedNumber;
}

export function FormatNumberToTwoDecimals(num) {
    // Round the number to two decimal places
    let roundedNum = num.toFixed(2);

    // Split the number into integer and decimal parts
    let [integerPart, decimalPart] = roundedNum.split('.');

    // Format the integer part with commas
    let formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Combine the formatted integer part with the decimal part
    let formattedNumber = `${formattedIntegerPart}.${decimalPart}`;

    return formattedNumber;
}

export function formatDateTime(dateString) {
    if (!dateString) return "Invalid date";
    const [datePart, timePart] = dateString.split(', ');
    if (!datePart || !timePart) return "Not Available";

    const [day, month, year] = datePart.split('/').map(Number);
    const [hours, minutes, seconds] = timePart.split(':').map(Number);

    const date = new Date(year, month - 1, day, hours, minutes, seconds);
    if (isNaN(date.getTime())) return "Invalid date";

    const now = new Date();
    const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    const startOfYesterday = new Date(startOfToday);
    startOfYesterday.setDate(startOfToday.getDate() - 1);

    let datePartFormatted;

    if (date >= startOfToday) {
        datePartFormatted = "Today";
    } else if (date >= startOfYesterday && date < startOfToday) {
        datePartFormatted = "Yesterday";
    } else {
        const differenceInMilliseconds = now - date;
        const differenceInDays = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

        if (differenceInDays < 7) {
            datePartFormatted = `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`;
        } else if (differenceInDays < 14) {
            datePartFormatted = "Last week";
        } else if (differenceInDays < 30) {
            const weeks = Math.floor(differenceInDays / 7);
            datePartFormatted = `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        } else if (differenceInDays < 60) {
            datePartFormatted = "Last month";
        } else if (differenceInDays < 365) {
            const months = Math.floor(differenceInDays / 30);
            datePartFormatted = `${months} month${months > 1 ? 's' : ''} ago`;
        } else if (differenceInDays < 730) {
            datePartFormatted = "Last year";
        } else {
            const years = Math.floor(differenceInDays / 365);
            datePartFormatted = `${years} year${years > 1 ? 's' : ''} ago`;
        }
    }

    const hour = date.getHours();
    const minute = date.getMinutes();
    const ampm = hour >= 12 ? 'PM' : 'AM';
    const formattedHour = hour % 12 || 12;
    const formattedMinute = minute < 10 ? `0${minute}` : minute;
    const timePartFormatted = `${formattedHour}:${formattedMinute} ${ampm}`;

    return `${datePartFormatted}, ${timePartFormatted}`;
}

export const convertValueToBoolean = (value) => {
    if (typeof value === 'string') {
        const lowerValue = value.toLowerCase();
        if (lowerValue === "yes" || lowerValue === "always allowed" || lowerValue === "allowed" || lowerValue === "Dynamic") {
            return true;
        } else if (lowerValue === "no" || lowerValue === "never allowed" || lowerValue === "blocked" || lowerValue === "None") {
            return false;
        }
    }
    return value;
};

export const isFieldEmpty = (value) => {
    return value === undefined || value === null || value === '' || value === 0;
};

export function removeCircularObjects(obj) {
    const seen = new WeakSet();

    function recurse(value) {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return undefined; // Remove circular reference
            }
            seen.add(value);
        }

        if (Array.isArray(value)) {
            return value.map(recurse);
        } else if (typeof value === "object" && value !== null) {
            const newObj = {};
            for (const key in value) {
                if (Object.hasOwnProperty.call(value, key)) {
                    const result = recurse(value[key]);
                    if (result !== undefined) {
                        newObj[key] = result;
                    }
                }
            }
            return newObj;
        }
        return value;
    }

    return recurse(obj);
}

export const adsHeader = () => {
    let token = useCookieStorageHelper.getItemCookie(JWT_TOKEN_KEY)
    let {email} = useWebStorageHelper.getItem(USER_DATA_KEY)

    return {
        'x-user-id': email,
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
}

export const getColorByKey = (currentValue, minValue, maxValue) => {

    const colorScale: string[] = [
        '#ba554c',  // Max point
        '#9f4a46',
        '#84413f',
        '#693838',
        '#4e3031',
        '#2A2A2A',  // Midpoint
        '#21403b',
        '#234c45',
        '#1e6d5e',
        '#237a68',
        '#2a8b6a'    // DarkViolet
    ];

    if (minValue === maxValue) return colorScale[0]; // Handle edge case where all values are the same

    const range = maxValue - minValue;
    const scaledValue = (currentValue - minValue) / range; // Normalize the value to [0, 1]
    const colorIndex = Math.min(Math.floor(scaledValue * (colorScale.length - 1)), colorScale.length - 1);
    return colorScale[colorIndex];

}

export const getMinMaxValueByKey = (data, key) => {

    if (!data || data.length === 0) {
        return { min: 0, max: 0 }; // Return 0 if there's no data
    }

    const values = data.map(item => item[key]);

    // Filter out non-numeric or undefined values
    const validValues = values.filter(value => typeof value === 'number' && !isNaN(value));

    if (validValues.length === 0) {
        return { min: 0, max: 0 }; // If no valid numeric values are found
    }

    const minValue = Math.min(...validValues);
    const maxValue = Math.max(...validValues);

    return { min: minValue, max: maxValue };
}


export const formatDateForGteLte = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0'); // Ensure 2 digits
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-based month
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
};

export const getInitialPage = (userRole = [], currentPath = "") => {
    if (currentPath !== "") {
        const actualPath = currentPath.replace("/dashboard", "");

        // Helper function to check if a path is allowed
        const isPathAllowed = (role, path) => {
            return USER_ROLE_TO_PATH_MAPPING[role].some(allowedPath => path.startsWith(allowedPath));
        };

        if (userRole.includes(ADMIN_UI_USER_ROLE)) {
            if (isPathAllowed(ADMIN_UI_USER_ROLE, actualPath)) {
                return currentPath;
            } else {
                return ADMIN_INITIAL_PAGE;
            }
        }

        if (userRole.includes(BUISNESS_UI_USER_ROLE)) {
            if (isPathAllowed(BUISNESS_UI_USER_ROLE, actualPath)) {
                return currentPath;
            } else {
                return BUISNESS_INITIAL_PAGE;
            }
        }

        if (userRole.includes(ONB_UI_USER_ROLE)) {
            if (isPathAllowed(ONB_UI_USER_ROLE, actualPath)) {
                return currentPath;
            } else {
                return ONB_INITIAL_PAGE;
            }
        }

        if (userRole.includes(ADOPS_UI_USER_ROLE)) {
            if (isPathAllowed(ADOPS_UI_USER_ROLE, actualPath)) {
                return currentPath;
            } else {
                return ADOPS_INITIAL_PAGE;
            }
        }
    } else {
        return "";
    }
};

export function checkSubstringInArray(array, substring) {
    let path = substring.split('/')
    return array.some(str => str?.toLowerCase()?.includes(path?.[2]?.toLowerCase()));
}

export const formattedEpochData = (epoch) => {
    if(!epoch){
        return {
            top: "-",
            bottom: "-"
        };
    }
    const timeStamp = parseInt(epoch);
    const date = new Date(timeStamp);
    const months = [
        "Jan", "Feb", "Mar", "Apr",
        "May", "Jun", "Jul", "Aug",
        "Sep", "Oct", "Nov", "Dec"
    ];
    const year = date.getFullYear();
    const month = months[date.getMonth()]; // Get the month name
    const day = date.getDate();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    // const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Get the system's timezone if needed in future
  
    return {
        top: `${month} ${day}, ${year}`,
        bottom: `${hours}:${minutes}:${seconds} IST`
    };
}

export const getNameFromEmail = (email) => {
    if(!email || typeof email !== "string"){
        return "";
    }
    let name = email.split('@')[0];
    let fName = name.split('.')[0];
    let lName = name.split('.')[1];
    return fName.charAt(0).toUpperCase() + fName.slice(1) + " " + lName.charAt(0).toUpperCase() + lName.slice(1);
}