import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormControl, Input } from "@mui/material";
import "./index.css";

const NumberInput = (props) => {
    const [error, setError] = useState(false);
    const [isFocused, setIsFocused] = useState(false); // Track focus state

    const validate = (value) => {
        if (props?.required && (value === "" || value === undefined)) {
            setError(true);
            return false;
        }
        if (props?.min !== undefined && value < props?.min) {
            setError(true);
            return false;
        }
        if (props?.max !== undefined && value > props?.max) {
            setError(true);
            return false;
        }
        setError(false);
        return true;
    };

    const handleChange = (event) => {
        const value = event.target.value === "" ? "" : Number(event.target.value);
        const isValid = validate(value);
        if (isValid) {
            props?.onChange(value);
        }
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
        validate(props?.value);
    };

    return (
        <div style={{width: props.width || "100%"}}>
            <FormControl
                className={`custom-select-container ${isFocused ? "focused" : ""}`}
                fullWidth
                style={{width: props?.width || "100%"}}
            >
                <Input
                    disabled={props?.disabled}
                    type="text"
                    value={props?.value || ""}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    placeholder={props?.placeholder || "Enter a number"}
                    disableUnderline
                    inputProps={{
                        inputMode: "numeric",
                        pattern: "[0-9]*",
                    }}
                    className="number-input-field"
                />
            </FormControl>
            {error && (
                <div className="custom-helper-text">
                    {props?.errorMessage || "This field is required."}
                </div>
            )}
        </div>
    )
};

NumberInput.propTypes = {
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    errorMessage: PropTypes.string,
    min: PropTypes.number,
    max: PropTypes.number,
    width: PropTypes.string,
};

export default NumberInput;
